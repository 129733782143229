<template>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div
      class="flex min-h-screen items-center justify-center px-4 py-6 text-center sm:p-0"
    >
      <!-- Background overlay -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div
          class="absolute inset-0 bg-gray-500 opacity-75 dark:bg-gray-900"
        ></div>
      </div>

      <!-- Modal panel -->
      <div
        class="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-gray-900 sm:my-8 sm:max-w-5xl sm:align-middle"
      >
        <!-- Header -->
        <div class="border-b border-gray-200 dark:border-gray-700 px-6 py-4">
          <div class="flex items-center justify-between">
            <div>
              <h3
                class="text-2xl font-bold leading-6 text-gray-900 dark:text-white"
              >
                Resource Usage & Limits
              </h3>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Monitor and track your organization's resource consumption and
                limits
              </p>
            </div>

            <Button
              @click="emitClose"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>

        <!-- Modal Body with Scroll -->
        <div class="max-h-[calc(100vh-16rem)] overflow-y-auto px-6 py-4">
          <!-- Loading State -->
          <div
            v-if="loading"
            class="flex h-[400px] items-center justify-center"
          >
            <Spinner size="large" />
          </div>

          <!-- Content -->
          <div v-else class="space-y-6">
            <remainingLimitsTable
              :limitstableBodyData="limitstableBodyData"
              :limitsRemainingData="limitsRemainingData"
              :orgProxyData="orgProxyData"
              @goToSubscriptionPage="emitClose"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import remainingLimitsTable from './remainingLimitsTable.vue'
import Spinner from '../Spinner.vue'
import { getOrganisationLimits } from '@/apis/user'

export default {
  name: 'remainingLimitsModal',
  components: {
    Input,
    SvgIcon,
    Button,
    remainingLimitsTable,
    Spinner
  },
  data() {
    return {
      loading: true,
      limitsRemainingData: null,
      limitstableBodyData: null,
      orgProxyData: null
    }
  },
  mounted() {
    this.getLimitsData()
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    async getLimitsData() {
      try {
        const response = await getOrganisationLimits()
        const {
          apiAccess,
          isPayAsYouGo,
          aiAccess,
          desktopUnlimited,
          integrationsAccess,
          desktopUnlimitedExecutionTime,
          proxyAccess,
          proxyBandwidth,
          ...tableObj
        } = response.data

        this.limitsRemainingData = {
          apiAccess,
          isPayAsYouGo,
          aiAccess,
          desktopUnlimited,
          integrationsAccess,
          desktopUnlimitedExecutionTime,
          proxyAccess
        }
        this.orgProxyData = proxyBandwidth
        this.limitstableBodyData = tableObj
      } catch (error) {
        console.error('Error fetching limits data:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
