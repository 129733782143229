<template>
  <div class="space-y-8">
    <!-- Usage Summary Section -->
    <div
      class="overflow-hidden rounded-2xl bg-white shadow-sm dark:bg-gray-900"
    >
      <!-- Add Reset Date Banner -->
      <div
        v-if="userData?.subscriptionEndDate"
        class="p-4 mt-2 bg-blue-50 dark:bg-blue-900/30 rounded-lg border border-blue-200 dark:border-blue-800"
      >
        <div class="flex items-center justify-between">
          <span class="text-sm font-medium text-blue-700 dark:text-blue-300">
            Limits Reset Date
          </span>
          <span class="text-sm font-bold text-blue-800 dark:text-blue-200">
            {{ getFormatDate(userData.subscriptionEndDate) }}
          </span>
        </div>
      </div>
      <div
        class="border-b border-gray-100 bg-gray-50/50 px-8 py-6 dark:border-gray-800 dark:bg-gray-800/50"
      >
        <h2 class="text-xl font-semibold text-gray-900 dark:text-gray-100">
          Resource Usage Overview
        </h2>
      </div>

      <div class="p-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Popper
            placement="top"
            hover
            :interactive="false"
            v-for="(item, key) in limitstableBodyData"
            :key="key"
          >
            <div
              class="group relative overflow-hidden rounded-lg border border-gray-100 bg-white p-4 shadow-sm transition-all duration-200 hover:border-blue-100 hover:shadow-md dark:border-gray-800 dark:bg-gray-800 dark:hover:border-blue-900"
            >
              <div class="space-y-2">
                <div class="flex items-center justify-between">
                  <span
                    class="text-sm font-medium text-gray-600 dark:text-gray-400"
                  >
                    {{ keyToMap[key] }}
                  </span>
                  <span
                    class="text-lg font-bold shrink-0 ml-2"
                    v-html="getColors(Object.values(item)[1], item, 1, key)"
                  >
                  </span>
                </div>

                <div class="relative">
                  <div
                    class="h-2 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-700"
                  >
                    <div
                      :style="`width: ${Math.min(
                        (Math.max(0, Object.values(item)[1]) / item.total) *
                          100,
                        100
                      )}%`"
                      :class="{
                        'bg-green-500 dark:bg-green-400':
                          (Math.max(0, Object.values(item)[1]) / item.total) *
                            100 >
                          75,
                        'bg-blue-500 dark:bg-blue-400':
                          (Math.max(0, Object.values(item)[1]) / item.total) *
                            100 >=
                            25 &&
                          (Math.max(0, Object.values(item)[1]) / item.total) *
                            100 <=
                            75,
                        'bg-red-500 dark:bg-red-400':
                          (Math.max(0, Object.values(item)[1]) / item.total) *
                            100 <
                          25
                      }"
                      class="h-full transition-all duration-500 ease-out"
                    ></div>
                  </div>
                </div>

                <div
                  class="text-xs font-medium text-gray-500 dark:text-gray-400"
                >
                  {{
                    key === 'executionTime'
                      ? `${formatExecutionTime(
                          Math.max(0, Object.values(item)[1])
                        )} / ${formatExecutionTime(item.total)} Left`
                      : `${Math.max(0, Object.values(item)[1])} / ${
                          item.total
                        } Left`
                  }}
                </div>
              </div>
            </div>
            <template #content>
              <div class="px-3 py-2">
                <div
                  class="w-max max-w-xl rounded-lg bg-blue-50 dark:bg-gray-800 p-3 text-sm text-gray-800 dark:text-gray-100 shadow-lg border border-blue-100 dark:border-gray-700"
                >
                  <div class="flex items-center space-x-2">
                    <SvgIcon
                      name="info"
                      class="w-4 h-4 text-blue-500 dark:text-blue-400 flex-shrink-0"
                    />
                    <span class="leading-relaxed">{{
                      keyToMapTooltip[key]
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Popper>
        </div>
      </div>
    </div>

    <!-- Proxy Info Section -->
    <div
      class="overflow-hidden rounded-2xl bg-white shadow-sm dark:bg-gray-900"
    >
      <div
        class="border-b border-gray-100 bg-gray-50/50 px-8 py-6 dark:border-gray-800 dark:bg-gray-800/50"
      >
        <h2 class="text-xl font-semibold text-gray-900 dark:text-gray-100">
          Organization Proxy Details
        </h2>
      </div>

      <div class="p-8">
        <div class="grid gap-4 md:grid-cols-2">
          <Popper
            placement="top"
            hover
            :interactive="false"
            v-for="(item, key) in orgProxyData"
            :key="key"
          >
            <div
              class="flex items-center justify-between rounded-xl border border-gray-100 bg-white p-5 shadow-sm dark:border-gray-800 dark:bg-gray-800"
            >
              <span
                class="text-sm font-medium text-gray-600 dark:text-gray-400 break-words"
              >
                {{ keyToMap[key] }}
              </span>
              <span
                class="text-sm font-semibold text-gray-900 dark:text-gray-100 ml-4"
              >
                {{
                  key === 'lastBandwidthSyncedAt'
                    ? item === ''
                      ? 'Not Synced Yet'
                      : getFormatDate(item)
                    : isNaN(item)
                    ? 0
                    : `${Math.max(0, item)} GB`
                }}
              </span>
            </div>
            <template #content>
              <div class="px-3 py-2">
                <div
                  class="w-max max-w-xl rounded-lg bg-blue-50 dark:bg-gray-800 p-3 text-sm text-gray-800 dark:text-gray-100 shadow-lg border border-blue-100 dark:border-gray-700"
                >
                  <div class="flex items-center space-x-2">
                    <SvgIcon
                      name="info"
                      class="w-4 h-4 text-blue-500 dark:text-blue-400 flex-shrink-0"
                    />
                    <span class="leading-relaxed">{{
                      keyToMapTooltip[key]
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Popper>
        </div>
      </div>
    </div>

    <!-- Access Status Section -->
    <div
      class="overflow-hidden rounded-2xl bg-white shadow-sm dark:bg-gray-900"
    >
      <div
        class="border-b border-gray-100 bg-gray-50/50 px-8 py-6 dark:border-gray-800 dark:bg-gray-800/50"
      >
        <h2 class="text-xl font-semibold text-gray-900 dark:text-gray-100">
          Access Status
        </h2>
      </div>

      <div class="p-8">
        <div class="grid gap-4 md:grid-cols-2">
          <Popper
            v-for="(data, key) in limitsRemainingData"
            :key="key"
            placement="top"
            hover
            :interactive="false"
          >
            <div
              class="flex items-center justify-between rounded-xl border border-gray-100 bg-white p-5 shadow-sm transition-all duration-200 hover:border-blue-100 hover:shadow-md dark:border-gray-800 dark:bg-gray-800 dark:hover:border-blue-900"
            >
              <span
                class="text-sm font-medium text-gray-600 dark:text-gray-400"
              >
                {{ keyToMap[key] }}
              </span>
              <span
                class="rounded-full px-3 py-1 text-xs font-medium ml-4"
                :class="
                  data
                    ? 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400'
                    : 'bg-blue-100 text-blue-700 cursor-pointer dark:bg-blue-900/30 dark:text-blue-400'
                "
                @click="
                  () => {
                    data === false && goToSubscriptionPage()
                  }
                "
              >
                {{ data ? 'Activated' : 'Upgrade' }}
              </span>
            </div>
            <template #content>
              <div class="px-3 py-2">
                <div
                  class="w-max max-w-xl rounded-lg bg-blue-50 dark:bg-gray-800 p-3 text-sm text-gray-800 dark:text-gray-100 shadow-lg border border-blue-100 dark:border-gray-700"
                >
                  <div class="flex items-center space-x-2">
                    <SvgIcon
                      name="info"
                      class="w-4 h-4 text-blue-500 dark:text-blue-400 flex-shrink-0"
                    />
                    <div class="flex flex-col">
                      <span class="leading-relaxed">{{
                        keyToMapTooltip[key]
                      }}</span>
                      <!-- TODO: MIGHT NEED LATER -->
                      <!-- <p
                        v-if="!data"
                        class="text-blue-500 cursor-pointer"
                        @click="goToSubscriptionPage()"
                      >
                        Upgrade your plan to activate it
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Popper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import {
  formatExecutionTime,
  formatDate,
  formatTime
} from '@/common/functions/formatDateAndTime'
import { mapState } from 'vuex'
import Popper from 'vue3-popper'

export default {
  name: 'remainingLimitsTable',
  components: {
    Input,
    SvgIcon,
    Button,
    Popper
  },
  props: {
    limitstableBodyData: {
      type: Object,

      required: true
    },
    limitsRemainingData: {
      type: Object,
      required: true
    },
    orgProxyData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('user', ['userData'])
  },
  data() {
    return {
      keyToMap: {
        members: 'Members',
        workspaces: 'Workspaces',
        concurrentExecution: 'Concurrent Execution',
        socialAccounts: 'Social Accounts',
        executionTime: 'Cloud Run Time',
        emailEnrichment: 'Data Enrichment',
        publicScraperCredit: 'Public Scraper Credit',
        apiAccess: 'API Access',
        isPayAsYouGo: 'Pay As You Go',
        aiAccess: 'AI Access',
        desktopUnlimited: 'Desktop Access',
        integrationsAccess: 'Integration Access',
        desktopUnlimitedExecutionTime: 'Desktop Unlimited',
        proxyAccess: 'Proxy Access',
        total: 'Total Organization Proxy',
        lastBandwidthSyncedAt: 'Last Bandwidth Synced At',
        consumed: 'Consumed'
      },
      keyToMapTooltip: {
        members: 'Maximum number of users allowed in your organization',
        workspaces: 'Total number of workspaces you can create',
        concurrentExecution:
          'Number of automation tasks that can run simultaneously',
        socialAccounts:
          'Maximum number of social media accounts you can connect',
        executionTime:
          'Total available time for running cloud-based automations',
        emailEnrichment: 'Number of data enrichment tasks available',
        publicScraperCredit: 'Available credits for scraping public data',
        apiAccess:
          'The ability to connect TexAu with external applications or services using an Application Programming Interface (API). This enables integration with external software for custom workflows and automation.',
        isPayAsYouGo:
          'A feature that allows users to purchase additional resources or features on demand. When deactivated, users are restricted to the resources provided within their current subscription plan.',
        aiAccess:
          "Access to TexAu's AI-powered tools and features that enhance automation capabilities. This allows users to leverage AI-driven functionalities, such as advanced decision-making or data processing, within their workflows.",
        desktopUnlimited:
          'Permission to use the TexAu desktop application. Users with desktop access can use the dedicated desktop application, which may offer enhanced performance or features compared to the browser interface.',
        integrationsAccess:
          'The ability to connect TexAu with third-party tools like Google Sheets, CRMs, or other platforms. This ensures smooth data transfer between TexAu and other tools, making workflows more efficient and collaborative.',
        desktopUnlimitedExecutionTime:
          "Unlimited access to TexAu's desktop application without usage restrictions. Users can freely utilize the desktop application for any supported tasks or operations without worrying about limitations.",

        proxyAccess:
          'Permission to use proxy servers for tasks that require anonymity or location-specific data access. With proxy access, users can perform operations such as web scraping, bypassing region-based restrictions, or maintaining data privacy.',
        total:
          'The total amount of bandwidth allocated for proxy-related activities within the organization.',
        lastBandwidthSyncedAt:
          'The most recent time when the bandwidth consumption data was updated in the system.',
        consumed:
          'The total amount of bandwidth already used by the organization for proxy operations.'
      }
    }
  },
  /**
   * Formats and applies color classes to a given value based on conditions.
   *
   * @param {string|number} ele - The value to be processed.
   * @param {Object} item - The current item containing the `total` property.
   * @param {number} index - The index of the current element.
   * @param {string} key - The key indicating which field is being processed (e.g., 'executionTime').
   * @returns {string} - The formatted HTML string with appropriate color classes.
   */
  methods: {
    getColors(ele, item, index, key) {
      const num = Number(ele)
      let eleToDisplay = isNaN(num) ? 0 : Math.max(0, num)
      if (index === 1) {
        var percentageLeft = (eleToDisplay / item.total) * 100

        if (key === 'executionTime') {
          eleToDisplay = formatExecutionTime(eleToDisplay)
        }

        // Apply color classes based on percentage to match progress bar
        if (percentageLeft < 25) {
          return `<p class='text-red-500 dark:text-red-400 font-bold'>${eleToDisplay}</p>`
        } else if (percentageLeft >= 25 && percentageLeft <= 75) {
          return `<p class='text-blue-500 dark:text-blue-400 font-bold'>${eleToDisplay}</p>`
        } else {
          return `<p class='text-green-500 dark:text-green-400 font-bold'>${eleToDisplay}</p>`
        }
      } else {
        if (key === 'executionTime') {
          let displayEle = formatExecutionTime(eleToDisplay)
          return `<p class='text-gray-900 dark:text-gray-100 font-bold'>${displayEle}0</p>`
        }
        return `<p class='text-gray-900 dark:text-gray-100 font-bold'>${eleToDisplay}</p>`
      }
    },
    getFormatDate(d) {
      return `${formatDate(d)}`
    },
    formatExecutionTime(time) {
      return formatExecutionTime(time)
    },
    goToSubscriptionPage() {
      this.$router.push('/settings-billing/plan')
      this.$emit('goToSubscriptionPage')
    }
  }
}
</script>

<style scoped></style>
