import { formatDate } from './formatDateAndTime'

const scheduleOptions = [
  'None',
  'At Regular Intervals',
  'Once',
  'Every Day',
  'Days Of The Week',
  'Days Of The Month',
  'Specific Dates'
]

/**
 * Formats a schedule time into a human-readable string.
 * @param {Object} time - The time object to format
 * @param {number} time.hour - The hour (0-23)
 * @param {number} time.minute - The minute (0-59)
 * @param {string} time.phase - The phase (AM/PM)
 * @returns {string} Formatted time string (e.g. "1:30 PM")
 */
const formatScheduleTime = time => {
  if (!time) return ''
  const hour = time.hour === 12 ? 12 : time.hour % 12
  const minute = time.minute.toString().padStart(2, '0')
  const phase = time.phase.toUpperCase()
  return `${hour}:${minute} ${phase}`
}

/**
 * Formats an array of days into day names
 * @param {number[]} days - Array of day indices (0=Sunday, 1=Monday, etc)
 * @returns {string} Comma-separated day names
 */
const formatDays = days => {
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  return days.map(day => dayNames[day]).join(', ')
}

/**
 * Formats dates with ordinal suffixes
 * @param {number[]} dates - Array of dates (1-31)
 * @returns {string} Formatted dates with suffixes
 */
const formatDates = dates => {
  return dates
    .sort((a, b) => a - b)
    .map(date => {
      const suffix = getOrdinalSuffix(date)
      return `${date}${suffix}`
    })
    .join(', ')
}

/**
 * Gets ordinal suffix for a number
 * @param {number} date - The date number
 * @returns {string} Ordinal suffix
 */
const getOrdinalSuffix = date => {
  if (date > 3 && date < 21) return 'th'
  switch (date % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

/**
 * Formats month numbers into month names
 * @param {number[]} months - Array of month indices (0-11)
 * @returns {string} Comma-separated month names
 */
const formatMonths = months => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return months
    .sort((a, b) => a - b)
    .map(month => monthNames[month])
    .join(', ')
}

/**
 * Gets formatted schedule details from a schedule object
 * @param {Object} schedule - The schedule configuration object
 * @returns {string} Formatted schedule details
 */
export const getWorkflowScheduleDetails = schedule => {
  console.log('schedule', schedule)

  if (!schedule?.option) return ''

  let details = scheduleOptions[schedule.option]

  if (schedule.option === 1) {
    details += ` (Every ${schedule.minutes} minutes). `
  } else if (schedule.rawData?.time) {
    details += ` at ${formatScheduleTime(schedule.rawData.time)} `
  }

  //on ${formatDate(schedule.rawData.date || schedule.rawData.endDate)}

  if (schedule.option === 4 && schedule.rawData?.days) {
    details += ` on ${formatDays(schedule.rawData.days)}. `
  }

  if (schedule.option === 5 && schedule.rawData?.dates) {
    details += ` on ${formatDates(schedule.rawData.dates)}`
  }

  if (schedule.option === 6) {
    if (schedule.rawData?.months) {
      details += ` in ${formatMonths(schedule.rawData.months)}`
    }
    if (schedule.rawData?.dates) {
      details += ` on ${formatDates(schedule.rawData.dates)}`
    }
  }

  if (schedule.rawData?.date) {
    details += ` on ${formatDate(schedule.rawData.date)}`
  } else if (schedule.rawData?.endDate) {
    details += ` Ends on ${formatDate(schedule.rawData.endDate)}`
  }

  if (schedule.timezone) {
    details += ` (${schedule.timezone})`
  }

  return details
}
