<template>
  <div
    class="relative flex flex-1 overflow-x-scroll border-l border-gray-200 dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between px-4 py-2 sm:p-3">
      <div class="flex h-full items-center justify-center" v-if="pageLoading">
        <Spinner size="large" />
      </div>
      <div class="flex-1 space-y-8" v-else>
        <div class="flex flex-col">
          <div class="overflow-x-hidden sm:-mx-6">
            <div
              class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-4"
            >
              <div
                class="min-2xl:max-h-[65vh] max-h-[55vh] overflow-y-auto overflow-x-hidden"
              >
                <div
                  v-if="
                    (automationData.length === 0 && isExecutionPresent) ||
                    loading
                  "
                  class="h-[50vh] flex items-center justify-center"
                >
                  <Spinner size="large" class="m-auto" />
                </div>
                <table v-else class="min-w-full">
                  <thead class="bg-gray-50 dark:bg-gray-800">
                    <tr>
                      <th
                        scope="col"
                        class="relative w-12 rounded-l-lg px-6 sm:w-16 sm:px-8"
                      >
                        <input
                          type="checkbox"
                          v-model="isCheckAll"
                          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-800 sm:left-6"
                          @click="checkAll()"
                        />
                      </th>

                      <th
                        scope="col"
                        class="min-w-[12rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Name
                      </th>

                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Platform
                      </th>

                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Schedule
                      </th>

                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Created
                      </th>

                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Elapsed Time
                      </th>

                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        class="min-w-fit rounded-r-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        <div class="flex justify-between">
                          <div>
                            <div
                              v-if="isWatchRowEnabled"
                              class="border border-blue-600 bg-transparent text-sm text-blue-600 px-3 py-2 font-semibold rounded-md"
                              disabled
                            >
                              Watch Row
                            </div>
                            <Button
                              v-else-if="isScheduled === true"
                              :text="
                                isInternalSchedule === true
                                  ? 'Stop'
                                  : 'Stop Schedule'
                              "
                              rightIcon="cancel"
                              color="danger"
                              size="small"
                              @click.prevent="stopSchedule()"
                            />
                          </div>

                          <Button
                            color="iconOnly"
                            class="-m-2 p-2 text-gray-900 bg-gray-200 cursor-not-allowed pointer-events-none"
                            :class="[
                              {
                                'text-red-600 bg-red-50 cursor-pointer pointer-events-auto ':
                                  executionIdArr.length > 0
                              }
                            ]"
                            icon="delete"
                            @click="deleteBulkExecution"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    v-if="automationData.length > 0"
                    class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  >
                    <AutomationRunDetails
                      v-for="data in automationData"
                      :key="data._id"
                      :executionId="data._id"
                      :executionIdArr="executionIdArr"
                      :workflowName="data.workflowName"
                      :desktop="data.desktop"
                      :schedule="data.schedule"
                      :createdAt="data.createdAt"
                      :status="data.status"
                      :statusReason="data.statusReason ?? null"
                      :workflowId="data.workflowId"
                      :humanElapsedTime="data.humanElapsedTime"
                      @ButtonClick="next"
                      @individualSelect="individualSelect"
                      @deleteApiResponse="deleteApiResponse"
                      @rerunAutomation="rerunAutomation"
                      @updateExecutionArr="updateExecutionArr"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 flex items-center justify-between">
        <div class="flex gap-3">
          <Button
            color="tertiary"
            text="Previous"
            leftIcon="left-arrow"
            @click="prevButtonClick()"
          />
          <Button
            v-if="$route.query.workflowId"
            color="tertiary"
            text="Show Logs"
            leftIcon="terminal"
            @click="toggleLogs()"
          />
        </div>
      </div>

      <!-- Logs Section -->
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-4"
      >
        <!-- class="fixed bottom-0 left-0 right-0 z-10 bg-white dark:bg-gray-900 shadow-lg rounded-t-lg border-t border-gray-200 dark:border-gray-700" -->
        <div
          v-if="showLogs"
          class="absolute bottom-0 px-4 left-0 right-0 z-10 bg-white dark:bg-gray-900 shadow-lg"
        >
          <Logs
            @toggleLogs="toggleLogs"
            :is-active="isWorkflowActive"
            :workflow-id="workflowId"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { mapActions, mapState } from 'vuex'
import AutomationRunDetails from '../AutomationRunDetails.vue'
import executionSettings from '../executionSettings.vue'
import Logs from '@/components/Logs.vue'

//API CALL
import {
  addWorkflowNode,
  createWorkflow,
  deleteExecution,
  getWorkflow,
  getWorkflowExecution,
  postExecution,
  updateWorkflow,
  updateWorkflowNode,
  pauseSchedule,
  reRunExecution,
  stopExecution
} from '@/apis/workflows'

import { WORKFLOW_CREATED_FROM } from '@/common/constants'

import {
  getWorkflowIdNew,
  getNodeIdNew,
  getNodeIdNewForSheetsOrCsv,
  workflowUpdateNew,
  updateWorkflowWithNewData,
  updateWorkflowNewDataForSheetsOrCsv
} from '@/common/functions/automationStore'

import { updateUserDetail } from '@/apis/user'

export default {
  name: 'DirectInputRun',
  components: {
    Input,
    Button,
    AutomationRunDetails,
    SvgIcon,
    Spinner,
    executionSettings,
    Logs
  },
  data() {
    return {
      status: 'run',
      workflowId: null,
      nodeId: null,
      workflowName: null,
      automationData: [],
      automationDataNew: [],
      pollInterval: null,
      pollingStarted: false,
      ids: [],
      executionIdArr: [],
      loading: false,
      pageLoading: true,
      isScheduled: false,
      isWatchRowEnabled: false,
      isInternalSchedule: false,
      isExecutionPresent: true,
      isCheckAll: false,
      isWorkflowRunning: false,
      isWorkflowActive: false,
      showLogs: false
    }
  },
  watch: {
    automationData: {
      handler(newVal, oldVal) {
        if (newVal.length > 0 && oldVal.length > 0) {
          if (newVal[0]?.status === 'running') {
            this.isWorkflowRunning = true
          } else {
            this.isWorkflowRunning = false
          }
          if (newVal[0]?.status === oldVal[0]?.status) {
            return
          }

          if (newVal[0].status === 'failed') {
            this.$emit('error', newVal[0].statusReason)
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    startAutomation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('automationStore', ['workflowIdCreated']),
    ...mapState('automationStore', ['executionId']),
    ...mapState('automationStore', ['AutomationStatus']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['validationStatus']),
    ...mapState('automationStore', ['automationNameText']),
    ...mapState('automationStore', ['delayAutomationNaming']),
    ...mapState('automationStore', ['automationOutputMode']),
    ...mapState('user', ['userData']),

    buttonColor() {
      if (this.executionId === null) {
        return 'disabled'
      }
      if (this.executionId) {
        return 'primary'
      }
    }
  },
  async created() {
    if (this.startAutomation) {
      // will check the url if workflow id is present or not, if not will
      // call the necessary api to create a workflow and node
      if (!this.$route.query.workflowId) {
        if (this.validationStatus === false) {
          this.$emit('error', 'Please Provide inputs to run the automation')
          this.pageLoading = false
          return
        }
        if (this.$route.query.publicWorkflowId) {
          await this.startPublicWorkflowAutomation()
        } else {
          await this.newStartAutomation()
        }
      } else {
        await this.updateNodeAndStartAutomation()
      }
    }
    this.pageLoading = false
    this.workflowExecutionGet()
    this.pollingStarted = true
    this.doPolling()
  },
  beforeUnmount() {
    clearInterval(this.pollInterval)
  },
  methods: {
    ...mapActions('user', ['updateUserData']),
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['toggleWorkflowIdCreated']),
    ...mapActions('automationStore', ['addExecutionId']),
    ...mapActions('automationStore', ['setDelayAutomationNaming']),
    checkAll() {
      this.isCheckAll = !this.isCheckAll
      this.executionIdArr = []
      if (this.isCheckAll) {
        for (const val of this.automationData) {
          this.executionIdArr.push(val._id)
        }
      }
      this.$emit('updateWorkflowIdToDelete', this.executionIdArr)
    },
    updateExecutionArr(data) {
      const index = this.executionIdArr.indexOf(data)
      if (index !== -1) {
        // If the element exists, remove it from the array
        this.executionIdArr.splice(index, 1)
      } else {
        // If the element doesn't exist, add it to the array
        this.executionIdArr.push(data)
      }

      if (this.automationData.length === this.executionIdArr.length) {
        this.isCheckAll = true
      } else {
        this.isCheckAll = false
      }
    },

    async startPublicWorkflowAutomation() {
      this.workflowId = this.$route.query.publicWorkflowId
      this.sendInputData(this.workflowId)
    },
    async newStartAutomation() {
      try {
        const queryData = await getWorkflowIdNew(this.$route.query.source)
        // will push the workflowId as query so that no new workflow id is created
        await this.$router.push({
          query: queryData
        })

        if (this.$route.query.source === 'input') {
          const nodeId = await getNodeIdNew(queryData.workflowId)
          await this.$router.replace({
            query: { ...queryData, nodeId }
          })
        } else {
          const nodeIds = await getNodeIdNewForSheetsOrCsv(
            queryData.workflowId,
            this.$route.query.source
          )
          await this.$router.replace({
            query: { ...queryData, ...nodeIds }
          })
        }

        this.sendInputData(queryData.workflowId)
      } catch (error) {
        this.$emit('error', error)
      }
    },
    async updateNodeAndStartAutomation() {
      try {
        if (this.$route.query.source === 'input') {
          await updateWorkflowWithNewData(this.$route.query.workflowId)
        } else {
          await updateWorkflowNewDataForSheetsOrCsv(
            this.$route.query.workflowId,
            this.$route.query.source
          )
        }

        this.workflowId = this.$route.query.workflowId

        // if any other execution id present in vue store, it removes it
        this.addExecutionId({
          payload: null
        })

        this.workflowExecutionGet()

        // will check if operation id is present or not , when reloaded the operation id data is removed from the
        // vue store so if it is present just add it again with workflow id and source
        if (this.$route.query.operationId) {
          //check if secondNode id is present or not
          if (this.$route.query.secondNodeId) {
            this.$router.push({
              query: {
                operationId: this.$route.query.operationId,
                workflowId: this.workflowId,
                source: this.$route.query.source,
                secondNodeId: this.$route.query.secondNodeId,
                platformId: this.$route.query.platformId
              }
            })
          } else {
            this.$router.push({
              query: {
                operationId: this.$route.query.operationId,
                workflowId: this.workflowId,
                source: this.$route.query.source,
                nodeId: this.$route.query.nodeId,
                platformId: this.$route.query.platformId
              }
            })
          }
        } else {
          if (this.$route.query.secondNodeId) {
            this.$router.push({
              query: {
                operationId: this.automationStoreData.platformOperationId,
                workflowId: this.workflowId,
                source: this.$route.query.source,
                secondNodeId: this.$route.query.secondNodeId
              }
            })
          } else {
            this.$router.push({
              query: {
                operationId: this.automationStoreData.platformOperationId,
                workflowId: this.workflowId,
                source: this.$route.query.source
              }
            })
          }
        }
        this.sendInputData()
      } catch (error) {
        this.$emit('error', error)
      }
    },

    next() {
      this.$emit('nextButtonClick', {
        comp: 'output-comp'
      })
    },

    async workflowExecutionGet() {
      try {
        const response = await getWorkflowExecution(
          (this.workflowId ?? this.$route.query.workflowId) ||
            this.$route.query.publicWorkflowId
        )
        if (response['success']) {
          this.automationDataNew = response.data

          this.automationDataNew.length === 0
            ? (this.isExecutionPresent = false)
            : (this.isExecutionPresent = true)
          const res = await getWorkflow(
            (this.workflowId ?? this.$route.query.workflowId) ||
              this.$route.query.publicWorkflowId
          )
          this.workflowName = res.data.name
          this.isScheduled = res.data.isScheduled ? res.data.isScheduled : false
          this.isWatchRowEnabled = res.data?.isWatchRowEnabled || false
          this.isWorkflowActive = res.data.isActive
          this.isInternalSchedule = res.data.isInternalSchedule
            ? res.data.isInternalSchedule
            : false
          this.$emit('setScheduledStatus', this.isScheduled)
          this.nodeId = res.data.startNode
          this.automationDataNew.forEach(obj => {
            obj.workflowName = this.workflowName
          })

          if (this.automationDataNew.length > 0) {
            if (
              this.automationDataNew.at(-1).status &&
              (this.automationDataNew.at(-1).status === 'completed' ||
                this.automationDataNew.at(-1).status === 'failed')
            ) {
              this.status = 'run'
            } else {
              this.status = 'running'
            }
          }

          this.automationData = this.automationDataNew
        } else {
          this.isExecutionPresent = false
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
      this.pageLoading = false
    },

    async doPolling() {
      this.pollInterval = setInterval(() => {
        this.workflowExecutionGet()
      }, 3000)
    },

    // start button is clicked and the execution process has started
    async sendInputData(workflowId) {
      if (this.validationStatus === false) {
        this.$emit('error', 'Please Provide inputs first to run the automation')
        return
      }

      this.$emit('deleteApiResponse', {
        type: 'success',
        value: 'Automation is starting please wait...'
      })

      this.status = 'running'
      try {
        const response = await postExecution(workflowId ?? this.workflowId)

        //check if this is user first automation run
        if (
          this.userData.firstAutomationRun !== undefined &&
          this.userData.firstAutomationRun === false
        ) {
          const res = await updateUserDetail({ firstAutomationRun: true })
          if (res['success']) {
            this.updateUserData({ payload: { firstAutomationRun: true } })
          }
        }

        if (response['success']) {
          if (!this.pollingStarted) {
            await this.workflowExecutionGet()
            this.doPolling()
          }
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    async rerunAutomation(data) {
      try {
        const response = await reRunExecution(data)

        if (response['success']) {
          this.status = 'running'
          this.$emit('deleteApiResponse', {
            type: 'success',
            value: 'Automation is starting please wait...'
          })
        } else {
          throw 'error'
        }
      } catch (error) {
        this.$emit('deleteApiResponse', {
          type: 'fail',
          value: 'Something Went Wrong While Re-running Automation'
        })
      }
    },

    prevButtonClick() {
      if (this.$route.query.source === 'input') {
        this.$emit('nextButtonClick', {
          comp: 'outputMode-comp',
          highlight: 4,
          optionalSidebarType: 'scheduling',
          optionalSidebarItem: 'center',
          optionalSidebarSearch: false
        })
      } else {
        this.$emit('nextButtonClick', {
          comp: 'outputMode-comp',
          highlight: 4,
          optionalSidebarType: 'scheduling',
          optionalSidebarItem: 'center',
          optionalSidebarSearch: false
        })
      }
    },
    async deleteBulkExecution() {
      try {
        const data = this.executionIdArr.map(val => {
          return {
            id: val
          }
        })

        const response = await deleteExecution({ ids: data })
        if (response['success']) {
          this.executionIdArr.length = 0
          this.loading = true
          this.$emit('deleteApiResponse', {
            type: 'success',
            value: 'Automation Successfully Deleted'
          })
        } else {
          this.$emit('deleteApiResponse', {
            type: 'fail',
            value: 'Something Went Wrong While Deleting The Automation'
          })
        }
      } catch (error) {
        this.$emit('deleteApiResponse', {
          type: 'fail',
          value: 'Something Went Wrong While Deleting The Automation'
        })
      }
    },
    async stopSchedule() {
      try {
        const response = await pauseSchedule(this.$route.query.workflowId)
        if (response['success']) {
          this.deleteApiResponse({
            type: 'success',
            value: 'Automation Schedule Stopped Successfully'
          })
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    deleteApiResponse(data) {
      this.$emit('deleteApiResponse', data)
    },

    toggleLogs() {
      this.showLogs = !this.showLogs
    }
  }
}
</script>
