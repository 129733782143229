<template>
  <div
    class="p-4 rounded-lg bg-white border border-gray-200 dark:border-gray-700"
    :style="props.width ? { width: props.width + 'px', margin: 'auto' } : {}"
  >
    <div class="flex justify-between items-center mb-4">
      <h3
        class="text-lg flex gap-2 items-center font-medium text-gray-900 dark:text-gray-100"
      >
        Execution Logs
        <Spinner v-if="pollLoader" class="h-6 w-6" />
      </h3>
      <button
        class="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
        @click="toggleLogs()"
      >
        <SvgIcon name="close" class="h-6 w-6" />
      </button>
    </div>
    <div
      class="max-h-[40vh] overflow-y-auto rounded-lg bg-gray-50 p-4 font-mono text-sm dark:bg-gray-800"
    >
      <div v-if="loading" class="flex justify-center py-4">
        <Spinner class="h-6 w-6" />
      </div>
      <div v-else-if="logsData.length === 0" class="text-center text-gray-500">
        No logs available
      </div>
      <div v-else v-for="log in logsData" :key="log._id" class="py-1">
        <span class="text-gray-500">{{ formatTimestamp(log.createdAt) }}</span>
        <span :class="getLogClass(log.logLevel?.toLowerCase())">
          [{{ log.logLevel || 'INFO' }}] {{ log.logText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import Spinner from '@/components/Spinner.vue'
import { getWorkflowLogs } from '@/apis/workflows'
import { useRoute } from 'vue-router'

const route = useRoute()
const emit = defineEmits(['toggleLogs'])

/**
 * @typedef {Object} Props
 * @property {string} workflowId - The ID of the workflow.
 * @property {boolean} isActive - Indicates if the workflow is active.
 * @property {string} [width] - Optional width for the component.
 */

/** @type {Props} */
const props = defineProps({
  workflowId: {
    type: String,
    required: true
  },
  isActive: {
    type: Boolean,
    default: false
  },
  width: {
    type: String
  }
})

const loading = ref(false)
const logsData = ref([])
const totalLogs = ref(0)
const currentStart = ref(0)
const LIMIT = 10
const POLLING_INTERVAL = 5000
const pollLoader = ref(false)
const remainingPolls = ref(0)
let pollingInterval

const stopPolling = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval)
    pollLoader.value = false
  }
}

/**
 * Starts polling for logs at a defined interval.
 */
const startPolling = () => {
  stopPolling()
  pollingInterval = setInterval(getLogs, POLLING_INTERVAL)
}

/**
 * Fetches the workflow logs and updates the logs data.
 *
 * This function retrieves logs for the specified workflow, updates the total logs count,
 * and manages the polling mechanism based on the workflow's active status.
 *
 * @async
 * @function getLogs
 * @returns {Promise<void>} A promise that resolves when the logs have been fetched and processed.
 * @throws {Error} Throws an error if there is an issue fetching the logs.
 */
const getLogs = async () => {
  try {
    pollLoader.value = true
    const logs = await getWorkflowLogs(
      route.query.workflowId || props.workflowId,
      currentStart.value,
      LIMIT
    )

    totalLogs.value = logs.total

    if (logs.data.length > 0) {
      const existingLogIds = new Set(logsData.value.map(log => log._id))
      const newLogs = logs.data.filter(log => !existingLogIds.has(log._id))

      if (newLogs.length > 0) {
        logsData.value = [...newLogs, ...logsData.value]
      }
    }

    if (
      logsData.value.length === LIMIT &&
      currentStart.value + LIMIT < totalLogs.value
    ) {
      currentStart.value += LIMIT
    }

    // stop polling if the workflow is not active and remaining polls are greater than 0
    if (!props.isActive && remainingPolls.value > 0) {
      remainingPolls.value--
      if (remainingPolls.value === 0) {
        stopPolling()
      }
    }
  } catch (error) {
    console.error('Error fetching logs:', error)
    pollLoader.value = false
  } finally {
    loading.value = false
  }
}

watch(
  () => props.isActive,
  newValue => {
    if (newValue === true) {
      startPolling()
    } else {
      remainingPolls.value = 2
    }
  },
  { immediate: true }
)

onMounted(async () => {
  loading.value = true
  await getLogs()
  if (props.isActive) {
    startPolling()
  }
  pollLoader.value = false
})

onUnmounted(() => {
  stopPolling()
})

/**
 * Toggles the visibility of the logs.
 */
const toggleLogs = () => {
  emit('toggleLogs')
}

/**
 * Formats a timestamp into a locale string.
 * @param {string} timestamp - The timestamp to format.
 * @returns {string} The formatted timestamp.
 */
const formatTimestamp = timestamp => {
  return new Date(timestamp).toLocaleString()
}

/**
 * Gets the CSS class for a log level.
 * @param {string} type - The log level type.
 * @returns {string} The corresponding CSS class.
 */
const getLogClass = type => {
  if (!type) return 'text-gray-600 dark:text-gray-400' // default style if type is undefined

  const classes = {
    info: 'text-blue-600 dark:text-blue-400',
    success: 'text-green-600 dark:text-green-400',
    warning: 'text-yellow-600 dark:text-yellow-400',
    error: 'text-red-600 dark:text-red-400'
  }
  return classes[type.toLowerCase()] || classes.info
}
</script>
