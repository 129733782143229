<template>
  <div>
    <div v-if="label" class="mb-1.5">
      <label :class="labelClass" class="flex">
        {{ label }} {{ isRequired === false ? '(Optional)' : null }}
        <SvgIcon
          class="mb-3 ml-1 h-2 w-2 text-red-600"
          name="star"
          v-if="isRequired"
        />
      </label>
    </div>

    <div
      ref="dropZoneRef"
      :class="{
        relative: isOverDropZone && attachmentSpecifications
      }"
    >
      <div
        v-if="isOverDropZone && attachmentSpecifications"
        class="absolute top-0 z-10 h-full w-full p-0.5 transition-all duration-300 ease-in-out"
      >
        <div
          class="flex h-full items-center justify-center rounded bg-blue-50 opacity-90 outline-dashed outline-2"
        >
          <div>
            <SvgIcon name="upload" />
          </div>
          Drop your files Here
        </div>
      </div>
      <div>
        <WorkflowRichTextEditor
          @blur="$emit('blur', $event)"
          @templateInserted="templateInserted"
          @templateRemoved="templateRemoved"
          v-model="componentValue.text"
          placeholder="Write your message or drag and drop files here"
          :previousNodes="previousNodes"
          :personalisedTags="personalisedTags"
          :hasFileAttachment="!!componentValue.file"
          :description="description"
          :isPromptIdProvided="isPromptIdProvided"
          :isPremium="isPremium"
          :platformOperationId="platformOperationId"
        >
          <template #toolbar>
            <!-- if attchment specification is provided show button  -->
            <label
              for="file-upload-rich-text"
              v-if="attachmentSpecifications"
              class="cursor-pointer rounded p-1 hover:bg-gray-200"
            >
              <SvgIcon class="h-5 w-5" name="attachment" />
              <input
                id="file-upload-rich-text"
                name="file-upload-rich-text"
                type="file"
                class="sr-only"
                ref="fileUploadInput"
                v-on:change="onDrop()"
              />
            </label>
          </template>
        </WorkflowRichTextEditor>
      </div>
    </div>

    <!-- File Preview -->
    <div
      v-if="previewUrl"
      class="flex h-fit mt-1 w-full items-center overflow-hidden rounded-lg border border-gray-300 px-4 py-2"
    >
      <figure class="flex flex-1">
        <div class="flex h-[40px] w-[40px] items-center justify-center">
          <img
            :src="previewUrl"
            alt=""
            srcset=""
            class="overflow-clip"
            style="overflow-clip-margin: content-box"
            @error="handleError"
          />
        </div>
        <figcaption v-if="uploadingStatus" class="flex-1 overflow-hidden px-2">
          <div class="flex h-full items-center justify-center text-blue-600">
            <SvgIcon name="spinnerIcon" />
          </div>
        </figcaption>
        <figcaption v-else class="flex-1 overflow-hidden px-2">
          <div class="flex">
            <h3 class="truncate text-sm">
              {{ fileName }}
            </h3>
            <span
              class="text-sm text-gray-500 before:px-1 before:content-['•']"
            >
              {{ getFileSizeInMb(fileSize) }} MB
            </span>
          </div>

          <p class="text-xs text-gray-500">Attached</p>
        </figcaption>
      </figure>
      <button @click="deletePreview()">
        <SvgIcon name="close" />
      </button>
    </div>

    <!-- Audio Player -->
    <div
      v-if="isAudioFile"
      class="mt-2 p-4 bg-white rounded-lg border border-gray-200"
    >
      <audio
        ref="audioPlayer"
        :src="accessibleFile?.attachment"
        class="hidden"
        @loadstart="isLoading = true"
        @canplay="isLoading = false"
        @loadedmetadata="handleMetadata"
        @timeupdate="updateProgress"
        @ended="handleEnded"
      ></audio>
      <div class="flex flex-col gap-3">
        <!-- Controls Row -->
        <div class="flex items-center gap-4">
          <!-- Play/Pause Button -->
          <button
            @click="togglePlay"
            :disabled="isLoading"
            class="flex items-center justify-center w-12 h-12 rounded-full bg-blue-600 hover:bg-blue-700 text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Spinner v-if="isLoading" class="text-white" size="small" />
            <SvgIcon
              v-else
              :name="isPlaying ? 'pause' : 'player-play'"
              class="h-6 w-6 text-white"
            />
          </button>

          <!-- Time Display -->
          <div class="text-sm text-gray-600">
            <span>{{ formatTime(currentTime) }}</span>
            <span class="mx-1">/</span>
            <span>{{ formatTime(duration) }}</span>
          </div>

          <!-- Volume Control -->
          <div class="flex items-center gap-2 ml-auto">
            <button
              @click="toggleMute"
              class="p-2 hover:bg-gray-100 rounded-full"
            >
              <SvgIcon
                :name="isMuted || volume === 0 ? 'pause' : 'play'"
                class="h-5 w-5 text-gray-600"
              />
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              v-model="volume"
              class="w-24"
            />
          </div>
        </div>

        <!-- Progress Bar -->
        <div class="relative">
          <div
            class="relative w-full h-1.5 bg-gray-200 rounded-full cursor-pointer"
            @click="seekAudio"
            @mousemove="updateHoverTime"
            @mouseleave="hoverTime = null"
          >
            <!-- Buffered Progress -->
            <div
              v-for="(buffered, i) in bufferedRanges"
              :key="i"
              class="absolute top-0 h-full bg-gray-300 rounded-full"
              :style="{
                left: `${buffered.start}%`,
                width: `${buffered.end - buffered.start}%`
              }"
            ></div>

            <!-- Playback Progress -->
            <div
              class="absolute top-0 h-full bg-blue-600 rounded-full"
              :style="{ width: `${progress}%` }"
            >
              <!-- Progress Knob -->
              <div
                class="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 w-3 h-3 bg-blue-600 rounded-full shadow-md"
              ></div>
            </div>

            <!-- Hover Time Preview -->
            <div
              v-if="hoverTime !== null"
              class="absolute bottom-full mb-2 px-2 py-1 text-xs bg-gray-800 text-white rounded transform -translate-x-1/2"
              :style="{ left: `${hoverPosition}%` }"
            >
              {{ formatTime(hoverTime) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPlaybackError"
      class="flex items-center mt-2 justify-between p-4 bg-red-100 border border-red-400 rounded-md text-red-600"
    >
      <span>Error fetching audio file for playback</span>
      <!-- <Button
        @click="retryFetchAudio"
        class="ml-4 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Retry
      </Button> -->
      <Button
        color="dangerFilled"
        text="Retry"
        size="small"
        @click="retryFetchAudio"
        :leftIcon="retryLoading ? null : 'refresh'"
        :showLoader="retryLoading"
        :disabled="retryLoading"
      />
    </div>
  </div>
</template>
<script setup>
import {
  deleteInputFile,
  uploadInputFile,
  getAccessibleFile
} from '@/apis/upload'
import { useDropZone } from '@vueuse/core'
import { ref, watch, computed, onMounted } from 'vue'
import SvgIcon from './SvgIcon.vue'
import DescriptionIcon from './DescriptionIcon.vue'
import { useRoute } from 'vue-router'
import WorkflowRichTextEditor from './workflowBuilder/customInput/WorkflowRichTextEditor.vue'
import axios from 'axios'
import Spinner from './Spinner.vue'
import Button from './Button.vue'
const dropZoneRef = ref(null)
const componentValue = ref({ text: '', file: null })
const file = ref(null)
const previewUrl = ref(null)
const uploadingStatus = ref(true)
const fileId = ref(null)
const fileName = ref(null)
const fileSize = ref(null)
const fileUploadInput = ref(null)
const route = useRoute()
const autoConnectOperationId = '640f16290936e46db5716df5'
const audioPlayer = ref(null)
const isPlaying = ref(false)
const duration = ref(0)
const currentTime = ref(0)
const progress = ref(0)
const accessibleFile = ref(null)
const isLoading = ref(false)
const volume = ref(1)
const isMuted = ref(false)
const hoverTime = ref(null)
const hoverPosition = ref(0)
const bufferedRanges = ref([])
const showPlaybackError = ref(false)
const retryLoading = ref(false)

onMounted(async () => {
  checkForLiAutoConnect()
  if (audioPlayer.value) {
    audioPlayer.value.addEventListener('timeupdate', () => {
      currentTime.value = audioPlayer.value.currentTime
      if (duration.value > 0) {
        progress.value = (currentTime.value / duration.value) * 100
      }
    })

    audioPlayer.value.addEventListener('ended', () => {
      isPlaying.value = false
      currentTime.value = 0
      progress.value = 0
    })

    audioPlayer.value.addEventListener('progress', updateBufferedRanges)
  }
  document.documentElement.style.setProperty('--volume-percentage', '100%')
})

/**
 * Props interface for RichTextEditor component
 * @typedef {Object} Props
 * @property {any} modelValue - The v-model value
 * @property {string} label - Label text for the editor
 * @property {string} labelClass - CSS class for the label
 * @property {string} type - Input type
 * @property {string} [placeholder='write something...'] - Placeholder text
 * @property {boolean} readonly - Whether the editor is readonly
 * @property {boolean} disabled - Whether the editor is disabled
 * @property {Array} previousNodes - Array of previous nodes
 * @property {boolean} [isRequired] - Whether the field is required
 * @property {string} modalClass - CSS class for the modal
 * @property {boolean} isLabelEditable - Whether the label can be edited
 * @property {Object} attachmentSpecifications - Specifications for file attachments
 * @property {Array} personalisedTags - Array of personalised tags
 * @property {string} description - Description text
 * @property {any} isPremium - Premium status
 * @property {any} platformOperationId - Platform operation ID
 */
const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  type: String,
  placeholder: { type: String, default: 'write something...' },
  readonly: Boolean,
  disabled: Boolean,
  previousNodes: Array,
  isRequired: { type: Boolean, default: undefined },
  modalClass: String,
  isLabelEditable: Boolean,
  attachmentSpecifications: Object,
  personalisedTags: Array,
  description: String,
  isPremium: {},
  platformOperationId: {}
})

/**
 * Mapping of file types to their preview URLs
 * @type {Object.<string, string>}
 */
const fileTypeToPreviewURL = {
  pdf: 'https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg',
  csv: 'https://upload.wikimedia.org/wikipedia/commons/c/c6/.csv_icon.svg',
  m4a: 'https://static-00.iconduck.com/assets.00/file-audio-icon-462x512-05b2lgxc.png',
  'x-m4a':
    'https://static-00.iconduck.com/assets.00/file-audio-icon-462x512-05b2lgxc.png'
}

const isPromptIdProvided = computed(() => {
  return componentValue.value.promptId ? true : false
})

// watch for model value change and update the component value
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue?.promptId) {
      componentValue.value = {
        text: `{{${props.modelValue.promptId}.${props.modelValue.promptLabel}}}`,
        file: props.modelValue.file,
        promptId: props.modelValue.promptId,
        promptLabel: props.modelValue.promptLabel
      }
    } else {
      componentValue.value = props.modelValue || { text: '', file: null }
    }
    // if file is present
    if (componentValue.value.file) {
      setPreviewURL(componentValue.value.file)
      fileId.value = componentValue.value.file.fileId
      fileName.value = componentValue.value.file.originalName
      fileSize.value = componentValue.value.file.fileSize
      uploadingStatus.value = false
      getAccessibleFileValue()
    }
  },
  { immediate: true }
)

watch(
  () => props.isPremium,
  () => {
    checkForLiAutoConnect()
    emit('update:modelValue', componentValue.value)
  }
)

const checkForLiAutoConnect = () => {
  if (
    route.query.operationId === autoConnectOperationId ||
    props.platformOperationId === autoConnectOperationId
  ) {
    componentValue.value['checkForCharCount'] = true
    componentValue.value['isPremium'] = props.isPremium
  }
}

function setPreviewURL(file) {
  const type = file.originalName?.split('.').pop()

  if (type in fileTypeToPreviewURL) {
    previewUrl.value = fileTypeToPreviewURL[type]
  } else {
    //create image file preview
    previewUrl.value = file.fileUrl
  }
}

/**
 * Handles file drop or selection event
 * @param {FileList} [files] - Files from drop event
 */
function onDrop(files) {
  try {
    // called when files are dropped on zone
    let unsupportedFile = true
    if (files) {
      file.value = files[0]
    } else {
      file.value = fileUploadInput.value.files[0]
    }

    //check if file extenstion is matches the attachment specification
    for (let type of props.attachmentSpecifications.supportedFormats) {
      if (file.value.type.split('/').pop() === type) {
        unsupportedFile = false
        break
      }
    }

    // if file is not supported
    if (unsupportedFile) {
      componentValue.value.file = 'unsupportedFile'
      emit('update:modelValue', componentValue.value)
      return
    }

    //file size check
    if (file.value.size > props.attachmentSpecifications.maxFileSize) {
      componentValue.value.file = 'size limit exceed'
      emit('update:modelValue', componentValue.value)
      return
    }

    if (file.value.type.split('/')[0] === 'image') {
      //create image file preview
      const reader = new FileReader()
      reader.onload = e => {
        previewUrl.value = e.target.result
      }
      reader.readAsDataURL(file.value)
    } else if (file.value.type.split('/').pop() in fileTypeToPreviewURL) {
      previewUrl.value = fileTypeToPreviewURL[file.value.type.split('/').pop()]
    } else {
      previewUrl.value =
        'https://static-00.iconduck.com/assets.00/share-emoji-512x512-j2qmf7um.png'
    }

    //upload the file to s3
    uploadFile()

    // Reset the file input value so it can trigger again for the same file
    if (fileUploadInput.value) {
      fileUploadInput.value.value = ''
    }
  } catch (error) {
    console.log(error)
    // Also reset on error
    if (fileUploadInput.value) {
      fileUploadInput.value.value = ''
    }
  }
}

/**
 * Uploads file to S3 and updates component state
 * @async
 * @throws {Error} When file upload fails
 */
async function uploadFile() {
  try {
    const formData = new FormData()
    formData.append('filename', file.value)
    uploadingStatus.value = true
    const response = await uploadInputFile(formData)
    if (response['success']) {
      fileId.value = response.data.fileId

      const fileUrl = response.data.fileUrl

      await getAccessibleFileValue()

      const txFileDetails = {
        fileId: fileId.value,
        originalName: file.value.name,
        fileSize: file.value.size,
        fileName: fileUrl.split('/').pop(),
        fileUrl,
        key: fileUrl.split('.com/')[1],
        bucket: fileUrl.split('/')[2].split('.')[0],
        region: fileUrl.split('/')[2].split('.')[2]
      }
      componentValue.value.file = txFileDetails
      emit('update:modelValue', componentValue.value)
      emit('blur')
    } else {
      throw error
    }
  } catch (error) {
    componentValue.value.file = 'api error'
    emit('update:modelValue', componentValue.value)
  } finally {
    uploadingStatus.value = false
  }
}

/**
 * Fetches accessible file data
 * @async
 */
async function getAccessibleFileValue() {
  try {
    const accessibleFileResponse = await getAccessibleFile(fileId.value)
    console.log(accessibleFileResponse)
    accessibleFile.value = accessibleFileResponse.data
    showPlaybackError.value = false
  } catch (error) {
    showPlaybackError.value = true
    emit('error', error.response.data.message)
  }
}

//check if file is over the dropzone
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop)

/**
 * Converts file size from bytes to megabytes
 * @param {number} fileSize - File size in bytes
 * @returns {string} File size in MB with 2 decimal places
 */
function getFileSizeInMb(fileSize) {
  return (fileSize / (1024 * 1024)).toFixed(2)
}

watch(file, () => {
  if (file.value) {
    fileSize.value = getFileSizeInMb(file.value.size)
    fileName.value = file.value.name
  }
})

//emits text value everytime it gets updated
watch(
  () => componentValue.value.text,

  () => {
    emit('update:modelValue', componentValue.value)
  }
)

/**
 * Handles template insertion and updates component state
 */
function templateInserted() {
  const inputString = componentValue.value.text
  const regex = /{{(.*?)}}/g
  const match = regex.exec(inputString)
  if (match) {
    const promptId = match[1].split('.')[0]
    componentValue.value['promptId'] = promptId
    componentValue.value.text = ''
    componentValue.value.promptLabel = match[1].split('.')[1]
  }
  emit('update:modelValue', componentValue.value)
  emit('blur')
  emit('toggleOpenAiAccounts', true)
}

/**
 * Handles template removal and resets component state
 */
function templateRemoved() {
  componentValue.value.text = ''
  delete componentValue.value.promptId
  delete componentValue.value.promptLabel
  emit('update:modelValue', componentValue.value)
  emit('blur')
  emit('toggleOpenAiAccounts', false)
}

/**
 * Deletes file preview and associated data
 * @async
 * @throws {Error} When file deletion fails
 */
async function deletePreview() {
  try {
    uploadingStatus.value = true
    const response = await deleteInputFile(fileId.value)
    if (response['success']) {
      accessibleFile.value = null
      audioPlayer.value = null
      file.value = null
      previewUrl.value = null
      componentValue.value.file = null
      uploadingStatus.value = false
      emit('update:modelValue', componentValue.value)
      emit('blur')
    }
  } catch (error) {
    console.log(error)
  }
}

const isAudioFile = computed(() => {
  const fileType = accessibleFile.value?.metadata?.name?.split('.').pop()
  return fileType === 'm4a' || fileType === 'x-m4a'
})

/**
 * Formats seconds into MM:SS format
 * @param {number} seconds - Time in seconds
 * @returns {string} Formatted time string
 */
function formatTime(seconds) {
  if (!seconds || isNaN(seconds)) return '0:00'
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins}:${secs.toString().padStart(2, '0')}`
}

/**
 * Toggles audio playback state
 */
function togglePlay() {
  if (!audioPlayer.value) return

  if (isPlaying.value) {
    audioPlayer.value.pause()
  } else {
    audioPlayer.value.play()
  }
  isPlaying.value = !isPlaying.value
}

/**
 * Handles audio seeking based on click position
 * @param {MouseEvent} event - Click event
 */
function seekAudio(event) {
  if (!audioPlayer.value) return
  const rect = event.currentTarget.getBoundingClientRect()
  const x = event.clientX - rect.left
  const percentage = x / rect.width
  const newTime = percentage * duration.value

  audioPlayer.value.currentTime = newTime
  currentTime.value = newTime
  progress.value = percentage * 100
}

function toggleMute() {
  if (!audioPlayer.value) return
  isMuted.value = !isMuted.value
  audioPlayer.value.muted = isMuted.value
}

watch(volume, newVolume => {
  if (!audioPlayer.value) return
  audioPlayer.value.volume = newVolume
  document.documentElement.style.setProperty(
    '--volume-percentage',
    `${newVolume * 100}%`
  )
})

/**
 * Updates hover time display based on mouse position
 * @param {MouseEvent} event - Mouse move event
 */
function updateHoverTime(event) {
  const rect = event.currentTarget.getBoundingClientRect()
  const x = event.clientX - rect.left
  const percentage = x / rect.width
  hoverPosition.value = percentage * 100
  hoverTime.value = percentage * duration.value
}

/**
 * Updates buffered ranges for audio player
 */
function updateBufferedRanges() {
  if (!audioPlayer.value) return
  const timeRanges = audioPlayer.value.buffered
  const ranges = []

  for (let i = 0; i < timeRanges.length; i++) {
    ranges.push({
      start: (timeRanges.start(i) / duration.value) * 100,
      end: (timeRanges.end(i) / duration.value) * 100
    })
  }

  bufferedRanges.value = ranges
}

/**
 * Updates audio progress
 */
function updateProgress() {
  if (!audioPlayer.value) return
  currentTime.value = audioPlayer.value.currentTime
  progress.value = (currentTime.value / duration.value) * 100
}

/**
 * Handles audio playback end
 */
function handleEnded() {
  isPlaying.value = false
  currentTime.value = 0
  progress.value = 0
}

/**
 * Handles audio metadata loading
 * @async
 */
async function handleMetadata() {
  duration.value = audioPlayer.value?.duration || 0

  // TODO: MIGHT NEED LATER
  // try {
  //   console.log('called')
  //   const response = await axios.get(accessibleFile.value?.attachment, {
  //     responseType: 'arraybuffer',
  //     withCredentials: true
  //   })
  //   const audioContext = new (window.AudioContext ||
  //     window.webkitAudioContext)()
  //   const audioBuffer = await audioContext.decodeAudioData(response.data)
  //   duration.value = audioBuffer.duration
  //   console.log('Audio duration:', duration.value)
  // } catch (error) {
  //   console.error('Error calculating audio duration:', error)
  //   console.log(audioPlayer.value?.duration || 0)
  //   duration.value = audioPlayer.value?.duration || 0
  // }
}

async function retryFetchAudio() {
  try {
    retryLoading.value = true
    await getAccessibleFileValue()
  } catch (error) {
    console.log(error)
  } finally {
    retryLoading.value = false
  }
}

/**
 * Handles image loading errors
 * @async
 * @param {Event} e - Error event
 */
async function handleError(e) {
  await getAccessibleFileValue()
  e.target.src = accessibleFile.value?.attachment
}

/**
 * Emits interface for RichTextEditor component
 * @typedef {Object} Emits
 * @property {(value: any) => void} 'update:modelValue' - Updates the model value
 * @property {() => void} 'label-update' - Emitted when label is updated
 * @property {() => void} 'blur' - Emitted on blur event
 * @property {(value: boolean) => void} 'toggleOpenAiAccounts' - Toggles OpenAI accounts
 * @property {(error: Error) => void} 'error' - Emitted on error
 */
const emit = defineEmits([
  'update:modelValue',
  'label-update',
  'blur',
  'toggleOpenAiAccounts',
  'error'
])
</script>

<style>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}

#custom-button {
  margin-right: 10px;
}

input[type='range'] {
  @apply appearance-none bg-gray-200 rounded h-1;
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none w-3 h-3 bg-blue-600 rounded-full cursor-pointer;
}
</style>

<style scoped>
input[type='range'] {
  @apply appearance-none bg-gray-200 rounded-full h-1 relative;
  background: linear-gradient(
    to right,
    #2563eb 0%,
    #2563eb var(--volume-percentage),
    #e5e7eb var(--volume-percentage),
    #e5e7eb 100%
  );
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none w-3 h-3 bg-blue-600 rounded-full cursor-pointer hover:bg-blue-700;
}

input[type='range']::-moz-range-thumb {
  @apply appearance-none w-3 h-3 bg-blue-600 rounded-full cursor-pointer border-0 hover:bg-blue-700;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply rounded-full;
}

input[type='range']::-moz-range-track {
  @apply rounded-full;
}

.fill-current {
  fill: currentColor;
}
</style>
