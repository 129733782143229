import axios from '@/common/axios'

const uploadInputFile = async formData => {
  try {
    const response = await axios.post(`/upload/input-file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteInputFile = async fileId => {
  try {
    const response = await axios.delete(`/attachment/${fileId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getAccessibleFile = async fileId => {
  const response = await axios.get(`/attachment/${fileId}`)
  return response.data
}

export { uploadInputFile, deleteInputFile, getAccessibleFile }
