<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-2xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 cursor-pointer sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Delete Workspaces
          </p>
        </div>
      </div>

      <div class="p-4 space-y-4">
        <!-- Plan Change Notice -->
        <div class="text-center space-y-2">
          <p class="text-lg font-semibold text-gray-800 dark:text-gray-200">
            {{ pendingActionData.name }}
          </p>
          <p class="text-gray-600 dark:text-gray-400">
            You need to delete
            <span class="font-semibold text-red-600 dark:text-red-400">
              {{ pendingActionData.metadata.numberOfWorkspacesToBeRemoved }}
              workspaces
            </span>
            to continue with the plan downgrade.
          </p>
        </div>

        <!-- Important Notice Section -->
        <div
          class="p-4 relative bg-amber-500/10 border border-amber-500/20 rounded-lg"
        >
          <div class="space-y-3">
            <p
              class="flex items-center text-amber-800 dark:text-amber-400 font-semibold gap-2"
            >
              <SvgIcon name="warning" class="w-5 h-5" />
              Important Notice
            </p>
            <ul class="space-y-2 text-sm text-amber-700 dark:text-amber-300">
              <li class="flex gap-2">
                <span>•</span>
                <p>
                  This action cannot be undone. All data in deleted workspaces
                  will be permanently removed.
                </p>
              </li>
              <li class="flex gap-2">
                <span>•</span>
                <p>
                  Make sure to back up any important data before proceeding.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Selection Progress -->
        <div class="bg-gray-50 dark:bg-gray-800 p-3 rounded-lg">
          <div class="flex justify-between items-center mb-2">
            <span class="text-sm text-gray-600 dark:text-gray-400"
              >Selection Progress</span
            >
            <span class="text-sm font-medium text-gray-900 dark:text-gray-100">
              {{ selectedWorkspaces.length }} /
              {{ pendingActionData.metadata.numberOfWorkspacesToBeRemoved }}
              required
            </span>
          </div>
          <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              class="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
              :style="`width: ${
                (selectedWorkspaces.length /
                  pendingActionData.metadata.numberOfWorkspacesToBeRemoved) *
                100
              }%`"
            ></div>
          </div>
        </div>

        <!-- Workspace Grid -->
        <div
          class="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[400px] overflow-y-auto"
        >
          <div
            v-for="workspace in selfWorkspaceData"
            :key="workspace.workspaceId"
            :class="[
              'p-4 rounded-lg border transition-all duration-200',
              selectedWorkspaces.includes(workspace.workspaceId)
                ? 'bg-red-100 border-red-300 dark:bg-red-900/30 dark:border-red-700'
                : 'bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700',
              showConfirmDelete ? 'cursor-not-allowed' : 'cursor-pointer'
            ]"
            @click="
              showConfirmDelete
                ? null
                : toggleWorkspaceSelection(workspace.workspaceId)
            "
          >
            <div class="flex items-center justify-between">
              <div>
                <p class="text-sm font-medium text-gray-900 dark:text-gray-100">
                  {{ workspace.name }}
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  {{
                    workspace.workspaceId === currentWorkspaceId
                      ? 'Current Workspace'
                      : ''
                  }}
                </p>
              </div>
              <div class="flex items-center gap-2">
                <div
                  class="w-4 h-4 rounded-full border-2 transition-colors duration-200"
                  :class="[
                    selectedWorkspaces.includes(workspace.workspaceId)
                      ? 'bg-red-500 border-red-500'
                      : 'border-gray-300 dark:border-gray-600'
                  ]"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <div
          class="flex items-center justify-between pt-4 border-t border-gray-200 dark:border-gray-700"
        >
          <p class="text-sm text-gray-600 dark:text-gray-400">
            {{ selectedWorkspaces.length }} of
            {{ pendingActionData.metadata.numberOfWorkspacesToBeRemoved }}
            workspaces selected
          </p>
          <div class="flex gap-3" v-if="showConfirmDelete">
            <Button
              text="Cancel"
              color="gray"
              @click="showConfirmDelete = false"
            />
            <Button
              text="Confirm Deletion"
              color="danger"
              :left-icon="loading ? '' : 'delete'"
              :show-loader="loading"
              @click="handleBulkDelete"
            />
          </div>
          <div class="flex gap-3" v-else>
            <Button
              text="Delete Selected Workspaces"
              :color="
                selectedWorkspaces.length !==
                pendingActionData.metadata.numberOfWorkspacesToBeRemoved
                  ? 'gray'
                  : 'danger'
              "
              :disabled="
                selectedWorkspaces.length !==
                pendingActionData.metadata.numberOfWorkspacesToBeRemoved
              "
              @click="showConfirmDelete = true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Button from '@/components/Button.vue'
import SvgIcon from '../SvgIcon.vue'
import { resolvePendingAction } from '@/apis/user'

/**
 * Emits events for success and error handling.
 * @type {Function}
 */
const emit = defineEmits(['success', 'error'])

/**
 * Router instance for navigation.
 * @type {Object}
 */
const router = useRouter()

/**
 * Vuex store instance for state management.
 * @type {Object}
 */
const store = useStore()

/**
 * Props for the component.
 * @type {Object}
 * @property {Object} pendingActionData - Data related to the pending action.
 * @property {boolean} pendingActionData.required - Indicates if the prop is required.
 */
const props = defineProps({
  pendingActionData: {
    type: Object,
    required: true
  }
})

// State management
/**
 * Loading state for asynchronous operations.
 * @type {Ref<boolean>}
 */
const loading = ref(false)

/**
 * Current workspace ID retrieved from cookies.
 * @type {Ref<string>}
 */
const currentWorkspaceId = ref(window.$cookies.get('workspaceId'))

/**
 * State to control the visibility of the confirmation delete dialog.
 * @type {Ref<boolean>}
 */
const showConfirmDelete = ref(false)

/**
 * Array of selected workspace IDs.
 * @type {Ref<string[]>}
 */
const selectedWorkspaces = ref([])

// Computed properties
/**
 * Computed property to filter self workspaces from the user workspace data.
 * @type {ComputedRef<Workspace[]>}
 */
const selfWorkspaceData = computed(() =>
  store.state.user.userWorkspaceData.filter(
    workspace => workspace.isSelfWorkspace === true
  )
)

/**
 * Validates if workspace can be added to selection
 * @param {string} workspaceId - Workspace identifier
 * @returns {boolean} Whether workspace can be selected
 */
const canSelectWorkspace = workspaceId => {
  return (
    !selectedWorkspaces.value.includes(workspaceId) &&
    selectedWorkspaces.value.length <
      props.pendingActionData.metadata.numberOfWorkspacesToBeRemoved
  )
}

/**
 * Toggles workspace selection state
 * @param {string} workspaceId - Workspace identifier
 */
const toggleWorkspaceSelection = workspaceId => {
  const index = selectedWorkspaces.value.indexOf(workspaceId)

  if (index === -1 && canSelectWorkspace(workspaceId)) {
    selectedWorkspaces.value.push(workspaceId)
  } else if (index !== -1) {
    selectedWorkspaces.value.splice(index, 1)
  }
}

/**
 * Updates cookies with new workspace information
 * @param {Workspace} workspace - Workspace to switch to
 */
const updateWorkspaceCookies = workspace => {
  sessionStorage.clear()
  window.$cookies.set('workspaceId', workspace.workspaceId, -1, '/')
  window.$cookies.set('orgUserId', workspace.orgUserId, -1, '/')
}

/**
 * Handles workspace switching if current workspace is being deleted
 * @param {Workspace[]} unselectedWorkspaces - List of remaining workspaces
 * @returns {void}
 */
const handleWorkspaceSwitch = unselectedWorkspaces => {
  const isCurrentWorkspaceSelected = selectedWorkspaces.value.includes(
    currentWorkspaceId.value
  )

  if (isCurrentWorkspaceSelected && unselectedWorkspaces.length > 0) {
    updateWorkspaceCookies(unselectedWorkspaces[0])
  }
}

/**
 * Processes the workspace deletion request
 * @param {string[]} workspaceIds - Array of workspace IDs to delete
 * @returns {Promise<boolean>} Success status of the operation
 * @throws {Error} If deletion fails
 */
const processWorkspaceDeletion = async workspaceIds => {
  const response = await resolvePendingAction(props.pendingActionData._id, {
    metadata: { workspaceIds }
  })

  if (!response.success) {
    throw new Error(response.message)
  }

  return response.success
}

/**
 * Handles the bulk deletion of selected workspaces
 * @async
 * @returns {Promise<void>}
 */
const handleBulkDelete = async () => {
  try {
    loading.value = true

    const unselectedWorkspaces = selfWorkspaceData.value.filter(
      workspace => !selectedWorkspaces.value.includes(workspace.workspaceId)
    )

    handleWorkspaceSwitch(unselectedWorkspaces)

    const success = await processWorkspaceDeletion(selectedWorkspaces.value)
    if (success) {
      router.go()
    }
  } catch (error) {
    emit('error', error)
    console.error('Error deleting workspaces:', error)
  } finally {
    loading.value = false
  }
}
</script>
