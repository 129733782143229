<template>
  <PromptTitleModal
    :promptTitleData="promptTitleData"
    @close="promptTitleModalClose"
    v-if="showPromptTitleModal"
  />
  <div
    class="shrink-0 bg-white text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <div class="px-4 sm:pb-5" :class="[{ '!pb-0 ': nav }, { 'pb-5': !nav }]">
      <div
        class="flex items-center"
        :class="[
          { 'justify-between': isRightButton || addProxyButton },
          { 'flex-1': bannerText }
        ]"
      >
        <div>
          <div class="flex items-center h-[40px]">
            <img
              v-if="iconUrl"
              class="h-10 w-10 shrink-0 rounded-full object-cover"
              :src="iconUrl"
              alt=""
            />
            <SvgIcon
              v-if="icon"
              class="h-10 w-10 shrink-0 rounded-full object-cover"
              :name="icon"
            />
            <h1
              v-if="promptTitle"
              id="titleEditor"
              class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50"
              :class="{ 'ml-3': space }"
            >
              {{ promptTitleData.title }}
            </h1>
            <h1
              v-else
              id="titleEditor"
              class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50"
              :class="{ 'ml-3': space }"
            >
              {{ title }}
            </h1>

            <div
              v-if="showCopyWorkflow"
              class="ml-2 flex items-center justify-center cursor-pointer"
            >
              <Popper hover>
                <SvgIcon
                  @click="copyWorkflowId"
                  class="text-blue-500"
                  name="copy"
                />
                <template #content>
                  <div class="w-max max-w-lg bg-gray-100 rounded px-2 text-sm">
                    Copy Workflow ID
                  </div>
                </template>
              </Popper>
            </div>

            <svg
              v-if="editableTitle && promptTitle"
              @click="oneditButtonClick()"
              class="ml-4 h-6 w-6 text-blue-600 cursor-pointer"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d="M11 3.99998H6.8C5.11984 3.99998 4.27976 3.99998 3.63803 4.32696C3.07354 4.61458 2.6146 5.07353 2.32698 5.63801C2 6.27975 2 7.11983 2 8.79998V17.2C2 18.8801 2 19.7202 2.32698 20.362C2.6146 20.9264 3.07354 21.3854 3.63803 21.673C4.27976 22 5.11984 22 6.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9264 19.673 20.362C20 19.7202 20 18.8801 20 17.2V13M7.99997 16H9.67452C10.1637 16 10.4083 16 10.6385 15.9447C10.8425 15.8957 11.0376 15.8149 11.2166 15.7053C11.4184 15.5816 11.5914 15.4086 11.9373 15.0627L21.5 5.49998C22.3284 4.67156 22.3284 3.32841 21.5 2.49998C20.6716 1.67156 19.3284 1.67155 18.5 2.49998L8.93723 12.0627C8.59133 12.4086 8.41838 12.5816 8.29469 12.7834C8.18504 12.9624 8.10423 13.1574 8.05523 13.3615C7.99997 13.5917 7.99997 13.8363 7.99997 14.3255V16Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p
            v-if="subtitle"
            class="mt-1 text-sm font-medium text-gray-600 dark:text-gray-400"
          >
            {{ subtitle }}
          </p>
        </div>

        <div v-if="isRightButton">
          <img
            v-if="isGoogleSelected"
            @click="rightButtonFunction"
            class="h-[46px] cursor-pointer"
            src="@/assets/images/logos/btn_google_signin_dark_normal_web@2x.png"
            alt="signin-with-google"
          />

          <Button
            v-else
            :name="rightButtonName ?? null"
            :text="rightButtonText"
            :class="{ hidden: rightButtonHidden }"
            class="!py-[6px]"
            leftIcon="plus"
            @click="rightButtonFunction"
          />
        </div>
        <div v-if="addProxyButton">
          <Button
            text="New Proxy"
            leftIcon="plus"
            class="!py-[6px]"
            @click="addProxyModalToggle"
          />
        </div>
      </div>
      <nav v-if="nav" class="mt-3 flex justify-between" aria-label="Tabs">
        <div class="flex space-x-8">
          <a
            href="#"
            title=""
            class="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-200 hover:text-gray-700"
            @click="navClick1()"
            :class="[
              {
                '!dark:border-gray-50 !border-gray-900 text-gray-900 dark:text-gray-50':
                  url
              },
              {
                'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                  !url
              }
            ]"
            aria-current="page"
          >
            {{ navFirst }}
          </a>

          <template v-if="showWatchRowTab">
            <div class="flex space-x-4">
              <!-- Watch Row Tab -->
              <a
                href="#"
                class="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-200 hover:text-gray-700"
                :class="[
                  {
                    '!dark:border-gray-50 !border-gray-900 text-gray-900 dark:text-gray-50':
                      scheduling
                  },
                  {
                    'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                      !scheduling
                  }
                ]"
                @click="
                  navClick({
                    comp: 'scheduling-comp',
                    highlight: 2,
                    optionalSidebarType: 'scheduling',
                    optionalSidebarItem: 'center',
                    optionalSidebarSearch: false
                  })
                "
              >
                <span class="flex items-center gap-1"> Watch Row</span>
              </a>

              <!-- Disabled Schedule Tab -->
              <!-- TODO: MIGHT NEED LATER -->
              <!-- <a
                href="#"
                class="flex items-center whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium cursor-not-allowed opacity-50"
              >
                <span class="flex items-center gap-1">
                  Schedule the Automation (Optional)
                  <SvgIcon name="lock" class="h-4 w-4 text-gray-400" />
                </span>
              </a> -->
            </div>
          </template>
          <template v-else>
            <a
              href="#"
              title=""
              class="flex items-center whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-200 hover:text-gray-700"
              @click="
                navClick({
                  comp: 'scheduling-comp',
                  highlight: 2,
                  optionalSidebarType: 'scheduling',
                  optionalSidebarItem: 'center',
                  optionalSidebarSearch: false
                })
              "
              :class="[
                {
                  '!dark:border-gray-50 !border-gray-900 text-gray-900 dark:text-gray-50':
                    scheduling
                },
                {
                  'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                    !scheduling
                }
              ]"
            >
              <span class="flex items-center gap-1">
                Schedule the Automation (Optional)
              </span>
            </a>
          </template>

          <a
            v-if="
              this.$route.query.source != 'input' &&
              operation.hasRandomDelay === true
            "
            href="#"
            title=""
            class="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-200 hover:text-gray-700"
            @click="
              navClick({
                comp: 'delay-comp',
                highlight: 3,
                optionalSidebarType: 'scheduling',
                optionalSidebarItem: 'center',
                optionalSidebarSearch: false
              })
            "
            :class="[
              {
                '!dark:border-gray-50 !border-gray-900 text-gray-900 dark:text-gray-50':
                  delay
              },
              {
                'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                  !delay
              }
            ]"
          >
            Iteration Delay (Optional)
          </a>

          <a
            href="#"
            title=""
            class="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-200 hover:text-gray-700"
            @click="
              navClick({
                comp: 'outputMode-comp',
                highlight: 4,
                optionalSidebarType: 'scheduling',
                optionalSidebarItem: 'center',
                optionalSidebarSearch: false
              })
            "
            :class="[
              {
                '!dark:border-gray-50 !border-gray-900 text-gray-900 dark:text-gray-50':
                  outputMode
              },
              {
                'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                  !outputMode
              }
            ]"
          >
            Output Mode (Optional)
          </a>

          <a
            href="#"
            title=""
            class="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium hover:border-gray-200 hover:text-gray-700"
            @click="
              navClick({
                comp: 'run-comp',
                highlight: 5,
                optionalSidebarType: 'run',
                optionalSidebarItem: 'center',
                optionalSidebarSearch: true
              })
            "
            :class="[
              {
                '!dark:border-gray-50 !border-gray-900 text-gray-900 dark:text-gray-50':
                  run
              },
              {
                'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                  !run
              }
            ]"
          >
            Run the Automation
          </a>
        </div>
        <div>
          <!-- <Button
            text="Texau Will Return"
            color="secondary"
            @click="$emit('openOutputModal')"
          /> -->
          <a
            href="#"
            title=""
            class="flex whitespace-nowrap px-1 py-4 text-sm font-medium text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-50"
            @click="$emit('openOutputModal')"
          >
            What data you get?
          </a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Popper from 'vue3-popper'
import Backdrop from '../Backdrop.vue'
import { mapState } from 'vuex'
import PromptTitleModal from '../settings/texauAI/PromptTitleModal.vue'

export default {
  name: 'AutomationHeader',
  emits: [
    'newPromptTitleData',
    'openOutputModal',
    'modalActivation',
    'onNavClick',
    'success'
  ],
  data() {
    return {
      url: false,
      scheduling: false,
      outputMode: false,
      delay: false,
      run: false,
      collect: false,
      modalActivate: this.modalActive,
      showPromptTitleModal: false,
      isDisabled: true
    }
  },
  props: {
    title: { type: String, default: 'Pass Title' },
    subtitle: { type: String },
    icon: { type: String, default: null },
    nav: { type: Boolean, default: false },
    highlight: { type: Number, default: 1 },
    navText: { type: String, default: 'url' },
    isRightButton: { type: Boolean, default: false },
    rightButtonText: { type: String, default: '' },
    rightButtonClass: { type: String, default: '' },
    rightButtonFunction: { type: Function, default: null },
    iconUrl: { type: String, default: '' },
    addProxyButton: { type: Boolean, default: false },
    modalActive: { type: Boolean, default: false },
    isGoogleSelected: { type: Boolean, default: false },
    bannerText: { type: String },
    rightButtonHidden: { type: Boolean, default: false },
    rightButtonName: { type: String },
    editableTitle: { type: Boolean, default: false },
    promptTitle: { type: Boolean, default: false },
    promptTitleData: { type: Object },
    operation: {}
  },
  computed: {
    ...mapState('user', ['additionalData', 'devModeStatus']),
    ...mapState('automationStore', ['automationScheduleData']),
    showCopyWorkflow() {
      return this.devModeStatus && this.$route.query.workflowId
    },
    space() {
      if (this.icon || this.iconUrl) {
        return true
      }
    },
    navFirst() {
      switch (this.navText) {
        case 'url':
          return 'Enter Inputs '
        case 'sheet':
          return 'Enter the Sheet Data to Extract'
        case 'csv':
          return 'Upload the CSV to Extract'
        default:
          return 'Enter Data to Extract'
      }
    },
    isScheduleDisabled() {
      return this.automationScheduleData?.isWatchRowEnabled === true
    },
    showWatchRowTab() {
      return this.automationScheduleData?.isWatchRowEnabled === true
    }
  },
  async created() {
    switch (this.highlight) {
      case 1:
        this.url = true

        break
      case 2:
        this.scheduling = true

        break

      case 3:
        this.delay = true

        break

      case 4:
        this.outputMode = true

        break
      case 5:
        this.run = true

        break
      case 6:
        this.collect = true

        break

      default:
        break
    }
  },

  methods: {
    async copyWorkflowId() {
      await navigator.clipboard.writeText(this.$route.query.workflowId)
      this.$emit('success', 'Workflow ID copied!')
    },
    addProxyModalToggle() {
      this.modalActivate = !this.modalActivate
      this.$emit('modalActivation', this.modalActivate)
    },
    navClick(data) {
      this.$emit('onNavClick', data)
    },
    navClick1() {
      this.$emit('onNavClick', {
        comp: `${this.$route.query.source}-comp`,
        highlight: 1,
        optionalSidebarType: 'default',
        optionalSidebarItem: 'center',
        optionalSidebarSearch: false
      })
    },
    oneditButtonClick() {
      this.showPromptTitleModal = true
    },
    promptTitleModalClose(data) {
      this.showPromptTitleModal = false
      this.$emit('newPromptTitleData', data)
    }
  },
  components: {
    SvgIcon,
    Button,
    PromptTitleModal,
    Popper
  }
}
</script>

<style></style>
