<template>
  <desktopSelectModal
    v-if="openDesktopModal"
    :currentSelected="desktopData"
    @close="openDesktopModal = false"
    @desktopSelected="onDesktopSelect"
    @desktopRemove="onDesktopRemove"
  />
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div
      class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:px-6 sm:pb-6 sm:pt-4"
    >
      <GoogleSheetFormValidation
        v-if="!pageLoading"
        :validateForm="validateFormTrigger"
        @resetFromValidation="resetFromValidation"
        @submitCreate="submitCreate"
        @showRedirect="showRedirect"
        @GoogleSignInClick="addOauthAccount"
        @error="onError"
        @success="onSuccess"
        @setDesktopToggle="setDesktopToggle"
        @googleConnectedAccountId="e => (googleConnectedAccountId = e)"
      />

      <div v-if="pageLoading" class="flex h-full items-center justify-center">
        <Spinner size="large" />
      </div>

      <div class="mt-8 flex items-center justify-end gap-x-2">
        <Button
          @click="openDesktopModal = true"
          v-if="showDesktopButton === true"
          :text="
            desktopSelected === false ? 'Select Desktop' : desktopData.name
          "
          :color="desktopSelected === false ? 'tertiary' : 'success'"
          rightIcon="desktop"
        />
        <!-- If the user has not verified either their email or phone, display this button -->
        <div>
          <Button
            v-if="!isVerified"
            color="gray"
            @click="showMessage"
            text="Run"
            rightIcon="right-arrow"
          />
          <Button
            v-else
            :text="buttonText"
            :rightIcon="rightIcon"
            :color="buttonColor"
            :disabled="isDisabled"
            @click="nextButtonClick()"
          />
        </div>
      </div>
    </div>
  </div>
  <ToastContainer class="w-1/5" ref="toast" />
</template>

<script>
import Select from '@/components/Select.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Label from '@/components/Label.vue'
import Checkboxes from '@/components/Checkboxes.vue'
import GoogleSheetFormValidation from '@/components/automationStore/googleSheets/GoogleSheetFormValidation.vue'
import Spinner from '@/components/Spinner.vue'
import { startOauthConnection } from '@/apis/oauth'
import ToastContainer from '@/components/ToastContainer.vue'
import { mapActions, mapState } from 'vuex'
import { constants } from '@/common/constants'
import desktopSelectModal from '@/components/automationStore/desktopSelectModal.vue'
import { showVerifyMessage } from '@/common/functions/automationStore'

export default {
  name: 'googleSheetsMain',
  components: {
    Select,
    Input,
    Button,
    Label,
    GoogleSheetFormValidation,
    Spinner,
    ToastContainer,
    Checkboxes,
    desktopSelectModal
  },
  data() {
    return {
      isLoading: false,
      pageLoading: false,
      validateFormTrigger: false,
      showDesktopButton: false,
      openDesktopModal: false,
      desktopSelected: false,
      desktopData: null,
      pickerApiLoaded: false,
      apiKey: 'AIzaSyAJrQWAO2uHdgMoW8Mc1LByBzKTtS5EcBw',
      gisInited: false,
      pickerInited: false,
      fileId: null,
      accessToken: '',
      googleConnectedAccountId: null
    }
  },
  props: {
    validateForm: {
      required: true
    }
  },
  watch: {
    validateForm(newVal, oldVal) {
      if (newVal === true) {
        this.validateFormTrigger = true
      }
    }
  },

  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['connectedAccountIdGoogleSheets']),
    ...mapState('workflow', ['dynamicOutputs']),
    ...mapState('automationStore', ['delayAutomationNaming']),
    ...mapState('automationStore', ['automationNameText']),
    ...mapState('automationStore', ['automationOutputMode']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('automationStore', ['validationStatus']),
    ...mapState('automationStore', ['automationDesktopData']),
    ...mapState('user', ['userData']),
    isVerified() {
      return this.userData.isEmailVerified && this.userData.isPhoneVerified
    },
    buttonText() {
      return this.automationScheduleData &&
        this.automationScheduleData.isWatchRowEnabled === false
        ? 'Schedule'
        : 'Run'
    },
    buttonColor() {
      return this.validationStatus ? 'successFilled' : 'gray'
    },
    isDisabled() {
      return !this.validationStatus
    },
    rightIcon() {
      return 'right-arrow'
    }
  },
  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['addSecondNodeId']),
    ...mapActions('automationStore', ['setDelayAutomationNaming']),
    ...mapActions('automationStore', ['addAutomationDesktopData']),

    nextButtonClick() {
      this.validateFormTrigger = true
    },

    submitCreate(data) {
      this.emitFormValidate()
    },

    emitFormValidate() {
      //As the trigger is from the navBar
      if (this.validateForm !== false) {
        this.$emit('AfterFormValidateFromNav')
      } else {
        this.$emit('AfterFormValidate')
      }
    },

    async addOauthAccount(appName) {
      this.isLoading = true
      const response = await startOauthConnection(appName)
      this.isLoading = false
      if (response['success']) {
        window.open(response.data.url, '_blank')
      } else {
        this.$emit('error', 'Error in adding new google account')
      }
    },
    resetFromValidation() {
      this.validateFormTrigger = false
      this.$emit('resetFromValidation')
    },
    showRedirect() {
      let isIntegration =
        this.automationStoreData.platformId === constants.OPENAI_PLATFORM_ID
      this.$refs.toast.addToast({
        timeout: 100000,
        text: 'Account Not Connected!',
        color: 'warning',
        caption:
          'You need to connect your account to run the automation, click the button below to connect your account',
        action: true,
        actionText: 'Click Here',
        actionHref: isIntegration
          ? '/settings-integrations/all-integrations'
          : '/accounts',
        close: true
      })
    },
    onError(data) {
      this.$emit('error', data)
    },
    onSuccess(data) {
      this.$emit('success', data)
    },
    setDesktopToggle() {
      const { authType, isSocialAccountOptional, isOptional } =
        this.automationStoreData

      const isCookieWithOptional =
        authType === 'cookie' && (isSocialAccountOptional || isOptional)

      const shouldShowDesktopButton =
        isCookieWithOptional ||
        authType === 'undefined' ||
        authType !== 'cookie'

      if (shouldShowDesktopButton) {
        this.showDesktopButton = true

        if (this.automationDesktopData?.name) {
          this.desktopData = this.automationDesktopData
          this.desktopSelected = true
        }
      }
    },
    onDesktopSelect(data) {
      this.desktopSelected = true
      this.desktopData = data
      this.addAutomationDesktopData(data)
    },
    onDesktopRemove() {
      this.desktopData = null
      this.desktopSelected = false
      this.addAutomationDesktopData(null)
    },
    /**
     * This function displays a verification message to the user in the form of a toast notification.
     */
    showMessage() {
      const message = showVerifyMessage(this.userData)
      const toastOptions = {
        timeout: 100000,
        text: 'Account Not Verified',
        color: 'warning',
        caption: message,
        action: true,
        actionText: 'Click Here To Verify',
        actionHref: '/settings-verify',
        close: true
      }
      this.$refs.toast.addToast(toastOptions)
    }
  }
}
</script>
