<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('close')"
    />
    <div
      class="z-10 max-h-[90vh] w-[90vw] max-w-[800px] rounded-[10px] bg-white"
    >
      <!-- Header -->
      <div class="relative border-b border-gray-200 px-6 py-4">
        <h2 class="text-lg font-bold text-center">Advanced Filters</h2>
        <button
          type="button"
          class="absolute right-4 top-3 h-8 w-8 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
          @click="$emit('close')"
        >
          <span class="sr-only">Close</span>
          <SvgIcon name="close" class="m-auto h-4 w-4" />
        </button>
      </div>

      <!-- Scrollable Content -->
      <div
        class="overflow-y-auto px-6 py-4"
        style="max-height: calc(90vh - 120px)"
      >
        <!-- Filter Sections -->
        <div class="flex flex-col gap-4">
          <!-- Date Range Filter -->
          <div class="rounded-lg border border-gray-200 p-4">
            <h3 class="mb-3 text-sm font-semibold flex items-center gap-2">
              <SvgIcon name="calendar" class="w-4 h-4" />
              Date Range Filter
            </h3>
            <div class="grid grid-cols-2 gap-3">
              <div>
                <label class="block text-xs font-medium text-gray-700 mb-1"
                  >Start Date</label
                >
                <Datepicker
                  v-model="dateFilters.startDate"
                  @update:model-value="onDateChange('startDate', $event)"
                  :max-date="dateFilters.endDate || new Date()"
                  :enable-time-picker="false"
                  placeholder="Select start date"
                  :teleport="true"
                  class="mt-1"
                  auto-apply
                  :preview-format="date => new Date(date).toLocaleDateString()"
                />
              </div>
              <div>
                <label class="block text-xs font-medium text-gray-700 mb-1"
                  >End Date</label
                >
                <Datepicker
                  v-model="dateFilters.endDate"
                  @update:model-value="onDateChange('endDate', $event)"
                  :min-date="dateFilters.startDate"
                  :max-date="new Date()"
                  :enable-time-picker="false"
                  placeholder="Select end date"
                  :teleport="true"
                  class="mt-1"
                  auto-apply
                  :preview-format="date => new Date(date).toLocaleDateString()"
                />
              </div>
            </div>
          </div>

          <!-- Platform Operations Filter -->
          <div class="rounded-lg border border-gray-200 p-4">
            <h3 class="mb-3 text-sm font-semibold flex items-center gap-2">
              <SvgIcon name="settings" class="w-4 h-4" />
              Platform Operations Filter
            </h3>
            <div class="flex gap-4">
              <!-- Platforms List -->
              <div class="w-1/3 border-r border-gray-300">
                <label class="block text-xs font-medium text-gray-700 mb-2"
                  >Select Platform</label
                >
                <div class="space-y-1 overflow-y-auto max-h-[250px] pr-2">
                  <div
                    v-for="platform in platforms"
                    :key="platform.value"
                    class="flex items-center p-1.5 rounded-md cursor-pointer hover:bg-gray-50 transition-colors text-sm"
                    :class="{
                      'bg-blue-50 border border-blue-200':
                        selectedPlatform === platform.value
                    }"
                    @click="selectPlatform(platform)"
                  >
                    <img
                      v-if="platform.img"
                      :src="platform.img"
                      class="w-5 h-5 mr-2 rounded-full"
                    />
                    <span>{{ platform.label }}</span>
                  </div>
                </div>
              </div>

              <!-- Operations List -->
              <div class="w-2/3">
                <label class="block text-xs font-medium text-gray-700 mb-2"
                  >Select Operation</label
                >
                <div class="mb-2">
                  <!-- <div class="flex items-center">
                    <SvgIcon name="search" class="h-4 w-4 text-gray-500 mr-2" />
                    <input
                      type="text"
                      v-model="searchQuery"
                      placeholder="Search operations..."
                      class="w-full px-2.5 py-1.5 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div> -->
                  <Input
                    v-model="searchQuery"
                    text="Search operations..."
                    left-icon="search"
                    class="w-full"
                  />
                </div>
                <div
                  v-if="loading"
                  class="flex items-center justify-center h-[200px]"
                >
                  <Spinner size="medium" />
                </div>
                <div
                  v-else-if="!selectedPlatform"
                  class="flex items-center justify-center h-[200px] text-sm text-gray-500"
                >
                  Select a platform to view its operations
                </div>
                <div
                  v-else
                  class="space-y-1.5 overflow-y-auto max-h-[200px] pr-2"
                >
                  <div
                    v-for="operation in filteredOperations"
                    :key="operation.platformOperationId"
                    class="p-2 border rounded-md cursor-pointer transition-colors duration-200 hover:shadow-sm"
                    :class="{
                      'border-blue-500 bg-blue-50 shadow-sm':
                        selectedOperation === operation.platformOperationId,
                      'border-gray-200 hover:border-blue-300':
                        selectedOperation !== operation.platformOperationId
                    }"
                    @click="selectOperation(operation)"
                  >
                    <div class="font-medium text-sm">{{ operation.name }}</div>
                    <div class="text-xs text-gray-500">
                      {{ operation.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Filter Button -->
      <div class="flex justify-end px-4 gap-2 py-3 border-t border-gray-200">
        <button
          type="button"
          class="inline-flex items-center justify-center h-9 px-4 text-sm font-medium border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-1"
          :class="{
            'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500':
              isFilterEnabled,
            'bg-gray-300 text-gray-500 cursor-not-allowed': !isFilterEnabled
          }"
          :disabled="!isFilterEnabled"
          @click="applyFilters"
        >
          Filter
        </button>
        <button
          type="button"
          class="inline-flex items-center justify-center h-9 px-4 text-sm font-medium border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-1 bg-gray-200 text-gray-700 hover:bg-gray-300"
          @click="resetFilters"
        >
          <SvgIcon name="refresh" class="mr-2" />
          Reset Filters
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Spinner from '@/components/Spinner.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { getPlatformOperations } from '@/apis/automation-store/Page1'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'

/**
 * Props for the AdvancedFiltersModal component.
 * @typedef {Object} Props
 * @property {Array} platforms - List of platforms to filter operations.
 */
const props = defineProps({
  platforms: {
    type: Array,
    required: true
  }
})

const emit = defineEmits([
  'close',
  'error',
  'dateFiltersChanged',
  'applyFilters'
])

const selectedPlatform = ref(null)
const operations = ref([])
const loading = ref(false)
const dateFilters = ref({
  startDate: null,
  endDate: null
})
const selectedOperation = ref(null)
const searchQuery = ref('')

/**
 * Computes the filtered operations based on the search query.
 * @returns {Array} - Filtered list of operations.
 */
const filteredOperations = computed(() => {
  if (!searchQuery.value) return operations.value
  const query = searchQuery.value.toLowerCase()
  return operations.value.filter(
    op =>
      op.name.toLowerCase().includes(query) ||
      op.label.toLowerCase().includes(query)
  )
})

/**
 * Checks if any filter is enabled.
 * @returns {boolean} - True if any filter is enabled, otherwise false.
 */
const isFilterEnabled = computed(() => {
  return (
    dateFilters.value.startDate ||
    dateFilters.value.endDate ||
    selectedOperation.value
  )
})

/**
 * Selects a platform and fetches its operations.
 * @param {Object} platform - The selected platform.
 */
const selectPlatform = async platform => {
  selectedPlatform.value = platform.value
  loading.value = true
  try {
    const response = await getPlatformOperations(platform.value)
    operations.value = response.data
  } catch (error) {
    emit('error', error)
  }
  loading.value = false
}

/**
 * Handles date change events for the date filters.
 * @param {string} type - The type of date ('startDate' or 'endDate').
 * @param {Date|null} value - The new date value.
 */
const onDateChange = (type, value) => {
  if (value === null) {
    dateFilters.value[type] = null
  } else {
    dateFilters.value[type] = new Date(value).toISOString().replace('.000', '')
  }
  emit('dateFiltersChanged', dateFilters.value)
}

/**
 * Selects an operation based on user interaction.
 * @param {Object} operation - The selected operation.
 */
const selectOperation = operation => {
  selectedOperation.value = operation.platformOperationId
}

/**
 * Applies the selected filters and emits the filter data.
 */
const applyFilters = () => {
  const filterData = {}

  if (dateFilters.value.startDate && dateFilters.value.endDate) {
    filterData.startDate = dateFilters.value.startDate
      ? new Date(dateFilters.value.startDate).toISOString()
      : null
    filterData.endDate = dateFilters.value.endDate
      ? new Date(dateFilters.value.endDate).toISOString()
      : null
  } else {
    filterData.startDate = null
    filterData.endDate = null
  }

  if (selectedPlatform.value) {
    filterData.platformId = selectedPlatform.value
  } else {
    filterData.platformId = null
  }

  if (selectedOperation.value) {
    filterData.platformOperationId = selectedOperation.value
  } else {
    filterData.platformOperationId = null
  }

  emit('applyFilters', filterData)
}

/**
 * Resets all filters to their initial state.
 */
const resetFilters = () => {
  dateFilters.value = { startDate: null, endDate: null }
  selectedPlatform.value = null
  selectedOperation.value = null
  searchQuery.value = ''
  emit('resetFilters')
}

/**
 * Parses URL parameters to initialize date filters.
 */
const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const startDate = urlParams.get('startDate')
  const endDate = urlParams.get('endDate')

  if (startDate) {
    dateFilters.value.startDate = new Date(startDate)
  }
  if (endDate) {
    dateFilters.value.endDate = new Date(endDate)
  }
}

// Initialize date filters from URL on component mount
onMounted(() => {
  getUrlParams()
})
</script>

<style>
.dp__action_select {
  background-color: #1c80cf !important;
}

.dp__action_select:hover {
  background-color: #125386 !important;
}

/* Custom scrollbar styles */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 2px;
}

/* Datepicker customization */
.dp__input {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
  font-size: 0.875rem !important;
}

.dp__main {
  font-size: 0.875rem !important;
}

.dp__action_buttons {
  padding: 0.5rem !important;
}
</style>
