<template>
  <div
    class="flex h-full flex-col rounded-[10px] bg-white px-8 py-10 text-gray-900 dark:bg-gray-900 dark:text-gray-50"
  >
    <Spinner v-if="loadingInputs" />
    <Scheduling
      v-if="!loadingInputs"
      :inputs="inputs"
      :option="option"
      :from="from"
      :trigger-validation="triggerValidation"
      @input-update="updateInputs"
      @option-update="updateOption"
      @validationSuccess="saveInputs"
      @validationFailed="triggerValidation = false"
      :watchRowToggle="props.watchRowToggle"
    />
    <div class="pl-[1px]">
      <Button
        v-if="!loadingInputs"
        @click="triggerValidation = true"
        class="mt-9 self-start"
      >
        Save {{ props.watchRowToggle ? 'Watch Row' : 'Schedule' }}
        <Spinner v-show="saving" size="small" class="ml-1 text-white" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { getWorkflow, updateWorkflow } from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Scheduling from '@/components/scheduling/config.vue'
import { convertToProperFormat } from '@/components/scheduling/utility'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
const inputs = ref({})
const option = ref(0)
const loadingInputs = ref(true)
const saving = ref(false)
const triggerValidation = ref(false)
const store = useStore()
const route = useRoute()

const emit = defineEmits(['success', 'error', 'changeToggleWatchRowValue'])

const props = defineProps({
  from: {
    type: String,
    default: 'builder'
  },
  watchRowToggle: {
    type: Boolean,
    default: false
  }
})

onMounted(async () => {
  try {
    if (!route.query.workflowId) {
      if (store._state.data.automationStore.automationScheduleData) {
        option.value =
          store._state.data.automationStore.automationScheduleData.schedule.option
        inputs.value =
          store._state.data.automationStore.automationScheduleData.schedule.rawData
      }
      loadingInputs.value = false
      return
    }
    const [response] = await Promise.all([getWorkflow(route.query.workflowId)])
    option.value = response.data.schedule?.option || 0
    inputs.value = response.data.schedule?.rawData || {}
    if (response.data?.isWatchRowEnabled) {
      emit('changeToggleWatchRowValue', response.data?.isWatchRowEnabled)
    }
    loadingInputs.value = false
  } catch (e) {
    emit('error', 'Something went wrong. Please try again in some time.')
  }
})

const updateInputs = updatedInputs => {
  inputs.value = updatedInputs
  if (inputs.value?.endDate) {
    const date = new Date(inputs.value?.endDate)
    const today = new Date()
    let obj = { ...updatedInputs }
    if (
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()
    ) {
      // Set the time to 23:59:59
      const newEndDate = new Date()
      newEndDate.setHours(23, 59, 59, 0)
      obj.endDate = newEndDate.toISOString()
      inputs.value = obj
    }
  }
}
const updateOption = newOption => {
  option.value = newOption
}

/**
 * Saves or updates workflow scheduling configuration
 * Handles both new schedules and updates to existing workflows
 */
const saveInputs = async e => {
  try {
    //check if end date is earlier than start date
    if (new Date(inputs.value.endDate) < new Date(inputs.value.startDate)) {
      emit('error', 'End date cannot be earlier than start date')
      triggerValidation.value = false
      saving.value = false
      return
    }
    // Validate schedule option if watch row is enabled
    if (props.watchRowToggle && (option.value === 0 || option.value === '')) {
      emit('error', 'Please select a valid schedule option')
      triggerValidation.value = false
      saving.value = false
      return
    }

    // Prepare schedule data
    let optionVal = option.value === '' ? 0 : option.value
    const structuredInput = convertToProperFormat(inputs.value, optionVal)
    const optionSchedulingData = {
      startDate: inputs.value.startDate,
      endDate: inputs.value.endDate,
      startTime: inputs.value.startTime,
      endTime: inputs.value.endTime,
      option: optionVal,
      timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone
    }

    // Log prepared data for debugging
    console.log('structuredInput', structuredInput)
    console.log('optionSchedulingData', optionSchedulingData)

    // Prepare schedule configuration object
    const scheduleConfig = {
      schedule: {
        ...structuredInput,
        ...optionSchedulingData,
        rawData: { ...inputs.value }
      },
      isScheduled:
        optionSchedulingData.option === 0 ? false : !props.watchRowToggle,
      isActive: false,
      isWatchRowEnabled: props.watchRowToggle
    }

    if (!route.query.workflowId) {
      // Handle new workflow schedule
      store.dispatch(
        'automationStore/addAutomationScheduleData',
        scheduleConfig
      )

      // Track event in analytics
      amplitudeTrackEvent('Workflow Schedule', localStorage.getItem('email'), {
        schedule: scheduleConfig.schedule
      })

      // Show success message
      emit(
        'success',
        props.watchRowToggle
          ? 'Watch Row Added Successfully'
          : 'Schedule Updated successfully'
      )
    } else {
      // Handle existing workflow update
      saving.value = true
      await updateWorkflow(route.query.workflowId, scheduleConfig)

      // Update store after successful API update
      store.dispatch(
        'automationStore/addAutomationScheduleData',
        scheduleConfig
      )

      // Show success message
      emit(
        'success',
        props.watchRowToggle
          ? 'Watch Row Added Successfully'
          : 'Schedule updated successfully'
      )
    }
  } catch (error) {
    // Handle errors
    console.log(error)
    emit(
      'error',
      'Failed to add or update the schedule. Please try again in some time.'
    )
  }

  // Reset state
  triggerValidation.value = false
  saving.value = false
}

// const saveInputs = async e => {
//   try {
//     if (props.watchRowToggle && (option.value === 0 || option.value === '')) {
//       emit('error', 'Please select a valid schedule option')
//       triggerValidation.value = false
//       saving.value = false
//       return
//     }

//     let optionVal = option.value === '' ? 0 : option.value
//     const structuredInput = convertToProperFormat(inputs.value, optionVal)
//     const optionSchedulingData = {
//       startDate: inputs.value.startDate,
//       endDate: inputs.value.endDate,
//       startTime: inputs.value.startTime,
//       endTime: inputs.value.endTime,
//       option: optionVal,
//       timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone
//     }
//     console.log('structuredInput', structuredInput)
//     console.log('optionSchedulingData', optionSchedulingData)
//     if (!route.query.workflowId) {
//       store.dispatch('automationStore/addAutomationScheduleData', {
//         schedule: {
//           ...structuredInput,
//           ...optionSchedulingData,
//           rawData: { ...inputs.value }
//         },
//         isScheduled:
//           optionSchedulingData.option === 0
//             ? false
//             : props.watchRowToggle
//             ? false
//             : true,
//         isActive: false,
//         isWatchRowEnabled: props.watchRowToggle
//       })
//       amplitudeTrackEvent('Workflow Schedule', localStorage.getItem('email'), {
//         schedule: {
//           ...structuredInput,
//           ...optionSchedulingData,
//           rawData: { ...inputs.value }
//         }
//       })

//       if (props.watchRowToggle) {
//         emit('success', 'Watch Row Added Successfully')
//       } else {
//         emit('success', 'Schedule Updated successfully')
//       }
//     } else {
//       saving.value = true
//       await updateWorkflow(route.query.workflowId, {
//         schedule: {
//           ...structuredInput,
//           ...optionSchedulingData,
//           rawData: inputs.value
//         },
//         isScheduled:
//           optionSchedulingData.option === 0
//             ? false
//             : props.watchRowToggle
//             ? false
//             : true,
//         isActive: false,
//         isWatchRowEnabled: props.watchRowToggle
//       })
//       store.dispatch('automationStore/addAutomationScheduleData', {
//         schedule: {
//           ...structuredInput,
//           ...optionSchedulingData,
//           rawData: { ...inputs.value }
//         },
//         isScheduled:
//           optionSchedulingData.option === 0
//             ? false
//             : props.watchRowToggle
//             ? false
//             : true,
//         isActive: false,
//         isWatchRowEnabled: props.watchRowToggle
//       })
//       emit(
//         'success',
//         props.watchRowToggle
//           ? 'Watch Row Added Successfully'
//           : 'Schedule updated successfully'
//       )
//     }
//   } catch (error) {
//     console.log(error)
//     emit(
//       'error',
//       'Failed to add or update the schedule. Please try again in some time.'
//     )
//   }
//   triggerValidation.value = false
//   saving.value = false
// }
</script>
