<template>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
    ></div>
    <div class="flex min-h-screen items-center justify-center p-4 text-center">
      <div
        class="relative w-full max-w-2xl transform overflow-hidden rounded-xl bg-white dark:bg-gray-900 p-8"
      >
        <!-- Header -->
        <div
          class="flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-6"
        >
          <div class="flex items-center space-x-4">
            <div
              class="h-12 w-12 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900"
            >
              <SvgIcon
                name="smiley"
                class="h-6 w-6 text-blue-600 dark:text-blue-400"
              />
            </div>
            <div>
              <p
                class="text-2xl text-left font-bold text-gray-900 dark:text-gray-50"
              >
                Create New User
              </p>
              <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Add a new user to your TexAu workspace
              </p>
            </div>
          </div>
          <Button
            @click="close"
            icon="close-1"
            class="!p-1.5"
            size="small"
            color="tertiary"
          />
        </div>

        <!-- Content wrapper with flex -->
        <div class="mt-8 flex flex-col min-h-[400px]">
          <!-- First view -->
          <div v-if="!showPhoneInput" class="flex-1 flex flex-col">
            <!-- Main content -->
            <div class="flex-1 space-y-8">
              <div class="flex items-start space-x-4">
                <div class="flex-shrink-0">
                  <div
                    class="h-10 w-10 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900"
                  >
                    <SvgIcon
                      name="info"
                      class="h-5 w-5 text-blue-600 dark:text-blue-400"
                    />
                  </div>
                </div>
                <div class="flex-grow text-left">
                  <h3
                    class="text-base font-medium text-gray-900 dark:text-gray-50 mb-1"
                  >
                    Create New Account
                  </h3>
                  <p
                    class="text-sm leading-relaxed text-gray-600 dark:text-gray-400"
                  >
                    This email address is not associated with a TexAu account.
                    Would you like to create a new account for this user?
                  </p>
                </div>
              </div>

              <div
                class="flex items-center space-x-4 px-8 py-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
              >
                <div
                  class="h-12 w-12 flex items-center justify-center rounded-full bg-white dark:bg-gray-700"
                >
                  <SvgIcon name="mail" class="h-6 w-6 text-gray-400" />
                </div>
                <div class="flex flex-col items-start">
                  <span
                    class="text-sm font-medium text-gray-500 dark:text-gray-400"
                    >Email Address</span
                  >
                  <span
                    class="text-base font-medium text-gray-900 dark:text-gray-50"
                    >{{ inviteeData.email }}</span
                  >
                </div>
              </div>
            </div>

            <!-- Actions -->
            <div
              class="flex justify-end space-x-3 pt-6 mt-auto border-t border-gray-200 dark:border-gray-700"
            >
              <Button
                text="Cancel"
                color="tertiary"
                class="w-28"
                @click="close"
              />
              <Button
                text="Continue"
                color="primary"
                class="w-28"
                @click="showPhoneInput = true"
              />
            </div>
          </div>

          <!-- Phone input view -->
          <div v-else class="flex-1 flex flex-col space-y-8">
            <!-- Main content -->
            <div class="flex-1 space-y-8">
              <div>
                <div
                  class="flex items-center mb-2 space-x-4 px-8 py-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                >
                  <div
                    class="h-12 w-12 flex items-center justify-center rounded-full bg-white dark:bg-gray-700"
                  >
                    <SvgIcon name="mail" class="h-6 w-6 text-gray-400" />
                  </div>
                  <div class="flex flex-col items-start">
                    <span
                      class="text-sm font-medium text-gray-500 dark:text-gray-400"
                      >Email Address</span
                    >
                    <span
                      class="text-base font-medium text-gray-900 dark:text-gray-50"
                      >{{ inviteeData.email }}</span
                    >
                  </div>
                </div>
                <p
                  class="text-base text-gray-600 dark:text-gray-400 text-left mb-2"
                >
                  Please provide a phone number for the new user account.
                </p>

                <div class="space-y-4">
                  <label
                    class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                    >Phone Number</label
                  >
                  <div class="flex space-x-2">
                    <Select
                      text="Country code"
                      :options="phoneCodes"
                      v-model="phoneCodeOption"
                      class="!m-0 !w-[180px] whitespace-nowrap"
                      :includeSearch="true"
                    />
                    <div class="flex w-full rounded-md shadow-sm">
                      <span
                        class="inline-flex items-center whitespace-nowrap rounded-l-md border border-r-0 border-gray-300 px-4 text-gray-500 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      >
                        {{ phoneCode }}
                      </span>
                      <input
                        v-model="phoneNumber"
                        type="number"
                        placeholder="9000090000"
                        class="block w-full rounded-lg rounded-l-none border border-gray-300 px-4 py-3 placeholder-gray-500 caret-blue-600 transition-all duration-200 [appearance:textfield] hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      />
                    </div>
                  </div>
                  <p v-if="phoneError" class="text-sm text-red-600">
                    Please enter a valid phone number
                  </p>
                </div>
              </div>

              <div class="rounded-lg bg-gray-50 dark:bg-gray-800 p-4">
                <p
                  class="text-xs text-gray-600 dark:text-gray-400 leading-relaxed"
                >
                  *I agree to receive automated transactional text messages
                  (e.g. password reset) at the phone number provided. Consent is
                  not a condition to purchase. Msg & data rates may apply.
                </p>
              </div>
            </div>

            <!-- Actions -->
            <div
              class="flex justify-end space-x-3 pt-6 mt-auto border-t border-gray-200 dark:border-gray-700"
            >
              <Button
                text="Create User"
                color="primary"
                class="w-40"
                :show-loader="loading"
                @click="createUser"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { phoneCountryCodes } from '@/common/constants'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { inviteUser } from '@/apis/workspace'

export default {
  name: 'CreateNewUserModal',
  components: {
    Button,
    Input,
    Select,
    SvgIcon
  },
  props: {
    inviteeData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPhoneInput: false,
      phoneNumber: '',
      phoneError: false,
      loading: false,
      phoneCodes: phoneCountryCodes.map(({ label }) => label),
      phoneCodeOption: 'United States'
    }
  },
  computed: {
    phoneCode() {
      return phoneCountryCodes.find(
        ({ label }) => label === this.phoneCodeOption
      )?.value
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    validatePhoneNumber() {
      try {
        const phoneNumber = parsePhoneNumberFromString(
          `${this.phoneCode}${this.phoneNumber}`
        )
        return phoneNumber && phoneNumber.isValid()
      } catch (error) {
        console.log('Error parsing phone number:', error)
        return false
      }
    },
    async createUser() {
      if (!this.validatePhoneNumber()) {
        this.phoneError = true
        return
      }

      this.loading = true
      try {
        const fullPhoneNumber = `${this.phoneCode}${this.phoneNumber}`
        const workspaceId = window.$cookies.get('workspaceId')
        const obj = {
          ...this.inviteeData,
          phone: fullPhoneNumber
        }
        const inviteResponse = await inviteUser(
          workspaceId,
          obj.email,
          obj.name,
          obj.roleId,
          obj.phone
        )
        if (inviteResponse['success']) {
          this.$emit('success', obj)
        } else {
          throw new Error(inviteResponse.message)
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
