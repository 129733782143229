<template>
  <div class="flex h-full w-full items-center justify-center" v-if="loading">
    <Spinner size="large" />
  </div>
  <layout-default
    v-else-if="checkUser() && checkOnboarded() && showLayout"
    v-slot="scope"
  >
    <AllocateBandwidthModal
      v-if="showAllocateBandwidthModal"
      :pendingActionData="pendingActionData"
      @close="showAllocateBandwidthModal = false"
      @error="scope.error"
      @success="scope.success"
    />
    <DeleteWorkspaceModal
      v-if="showDeleteWorkspaceModal"
      :pendingActionData="pendingActionData"
      @close="showDeleteWorkspaceModal = false"
      @error="scope.error"
      @success="scope.success"
    />
    <router-view
      @error="scope.error"
      @success="scope.success"
      @warning="scope.warning"
      @showAllocationModal="checkForPendingAction"
    />
  </layout-default>

  <layout-generic v-else v-slot="scope">
    <router-view
      @error="scope.error"
      @success="scope.success"
      @warning="scope.warning"
    />
  </layout-generic>
</template>

<script>
import { getBaseUser, getUsers, getPendingAction } from '@/apis/user'
import { getAllActivities } from '@/apis/activities'
import UserProfile from '@/common/userWrapper'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutGeneric from '@/layouts/LayoutGeneric.vue'
import { mapActions, mapState } from 'vuex'
import Spinner from './components/Spinner.vue'
import { H } from 'highlight.run'
import { getExecutionTimeLeft } from '@/common/functions/executionTimeFetch'
import AllocateBandwidthModal from '@/components/pendingActionModals/AllocateBandwidthModal.vue'
import DeleteWorkspaceModal from '@/components/pendingActionModals/DeleteWorkspaceModal.vue'

export default {
  name: 'App',
  components: {
    LayoutDefault,
    LayoutGeneric,
    Spinner,
    AllocateBandwidthModal,
    DeleteWorkspaceModal
  },
  async created() {
    if (UserProfile.getUser()) {
      await this.getBaseUser()
    }

    // TODO: will be needed when adding dark mode

    // if (!localStorage.getItem('theme')) {
    //   if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //     localStorage.setItem('theme', 'dark')
    //     document.documentElement.classList.add('dark')
    //   } else {
    //     localStorage.setItem('theme', 'light')
    //     document.documentElement.classList.remove('dark')
    //   }
    // } else {
    //   if (localStorage.getItem('theme') === 'dark') {
    //     document.documentElement.classList.add('dark')
    //   } else {
    //     document.documentElement.classList.remove('dark')
    //   }
    // }

    //check if user data(auth user) is available or not
    if (Object.keys(this.userData).length > 0) {
      H.identify(this.userData.email, {
        firstName: this.userData.firstname,
        lastName: this.userData.lastname,
        organisationId: window.$cookies.get('organisationId'),
        orgUserId: window.$cookies.get('orgUserId'),
        workspaceId: window.$cookies.get('workspaceId')
      })

      window.clarity(
        'identify',
        this.userData.email,
        '',
        this.$route.params?.id
      )
    } else {
      try {
        //set ip address as identifier
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        H.identify(data.ip, {})
      } catch (error) {
        console.error('Error:', error)
      }
    }

    this.loading = false

    this.checkForPendingAction()
  },

  data() {
    return {
      loading: true,
      showAllocateBandwidthModal: false,
      pendingActionData: null,
      showDeleteWorkspaceModal: false
    }
  },

  computed: {
    showLayout() {
      return this.$route.meta.layout !== false
    },
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['saveUserData']),
    ...mapActions('user', ['saveNotificationNumber']),
    ...mapActions('user', ['saveWorkspaceData']),

    async getBaseUser() {
      const [baseUserResponse, activityResponse, userResponse] =
        await Promise.all([
          getBaseUser(),
          getAllActivities(),
          getUsers(),
          getExecutionTimeLeft()
        ])
      if (baseUserResponse['success']) {
        this.saveUserData({ payload: baseUserResponse.data })
      } else {
        return 'Unable to fetch your account data.'
      }
      if (activityResponse['success']) {
        this.saveNotificationNumber({ payload: activityResponse.total })
      }
      if (userResponse['success']) {
        this.saveWorkspaceData({ payload: userResponse.data })
      }
    },

    checkUser() {
      return !!UserProfile.getUser()
    },
    checkOnboarded() {
      const user = UserProfile.getUser()
      return user.isOnboarded
    },

    /**
     * Checks for any pending actions related to bandwidth allocation and updates the component's state.
     * If a relevant pending action is found, it sets `pendingActionData` with the action details
     * and displays the pending action modal.
     *
     * @async
     * @function checkForPendingAction
     * @returns {Promise<void>} Resolves without returning a value.
     * @throws Will log an error if the API call to retrieve pending actions fails.
     */
    async checkForPendingAction() {
      try {
        const response = await getPendingAction()
        if (response.data.length === 0) return
        if (response.data[0].actionStatus === 'todo') {
          switch (response.data[0].actionType) {
            case 'allocate-bandwidth':
            case 're-allocate-bandwidth':
              this.pendingActionData = response.data[0]
              this.showAllocateBandwidthModal = true
              break
            case 'delete-workspaces':
              this.pendingActionData = response.data[0]
              this.showDeleteWorkspaceModal = true
              break
            default:
              break
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
