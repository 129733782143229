<template>
  <div>
    <Select
      :options="scheduleOptions.map((v, i) => ({ label: v, value: i }))"
      :model-value="option"
      @update:model-value="optionUpdate"
      :label="
        watchRowToggle
          ? 'Select Watch Row Mode (optional)'
          : 'Select Scheduling Mode (optional)'
      "
      class="mb-8"
    />
    <ValidationForm
      :platformInputs="inputFields"
      :previousNodes="[]"
      :inputs="inputs"
      :triggerValidation="triggerValidation"
      class="grid-cols-1"
      :class="[{ 'max-h-[280px]': from === 'builder' }]"
      @input-update="inputUpdate"
      @validationSuccess="e => $emit('validationSuccess', e)"
      @validationFailed="e => $emit('validationFailed', e)"
    />
  </div>
</template>

<script setup>
import ValidationForm from '@/components/ValidationForm.vue'
import { computed } from 'vue'
import Select from '../Select.vue'
import { scheduleInputs } from './scheduleInputs'
const scheduleOptions = [
  'None',
  'At Regular Intervals',
  'Once',
  'Every Day',
  'Days Of The Week',
  'Days Of The Month',
  'Specific Dates'
]
const inputFields = computed(() => {
  if (props.watchRowToggle) {
    //if option is undefined, return empty array
    if (!props.option) {
      return []
    }
    let newOption = [...scheduleInputs[props.option]].map(item => {
      if (item.name === 'endDate') {
        item.isRequired = true
        item.label = 'End Date'
      }
      return item
    })
    return newOption
  }
  return scheduleInputs[props.option]
})

const props = defineProps({
  inputs: {
    required: true
  },
  option: {
    required: true
  },
  from: {
    type: String,
    default: 'builder'
  },
  watchRowToggle: {
    type: Boolean,
    default: false
  },
  triggerValidation: Boolean
})
const emit = defineEmits([
  'input-update',
  'option-update',
  'validationSuccess',
  'validationFailed'
])

const inputUpdate = val => emit('input-update', val)
const optionUpdate = val => emit('option-update', val)
</script>
